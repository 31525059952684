import {
  KPI_CHANGE_PERIOD,
  TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR,
  TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST,
  TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS,
  TRANSACTIONS_SUCCEEDED_DETAIL_ERROR,
  TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST,
  TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR,
  TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST,
  TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR,
  TRANSACTIONS_DISPUTED_AMOUNT_REQUEST,
  TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS,
  TRANSACTIONS_DISPUTED_AMOUNT_ERROR,
  TRANSACTIONS_DISPUTED_DETAIL_REQUEST,
  TRANSACTIONS_DISPUTED_DETAIL_SUCCESS,
  TRANSACTIONS_DISPUTED_DETAIL_ERROR,
  TRANSACTIONS_FAILED_AMOUNT_REQUEST,
  TRANSACTIONS_FAILED_AMOUNT_SUCCESS,
  TRANSACTIONS_FAILED_AMOUNT_ERROR,
  TRANSACTIONS_FAILED_DETAIL_REQUEST,
  TRANSACTIONS_FAILED_DETAIL_SUCCESS,
  TRANSACTIONS_FAILED_DETAIL_ERROR,
  TRANSACTIONS_AVERAGE_TICKET_REQUEST,
  TRANSACTIONS_AVERAGE_TICKET_SUCCESS,
  TRANSACTIONS_AVERAGE_TICKET_ERROR,
  TRANSFERS_SUCCEEDED_DETAIL_REQUEST,
  TRANSFERS_SUCCEEDED_DETAIL_SUCCESS,
  TRANSFERS_SUCCEEDED_DETAIL_ERROR,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR,
  TRANSFERS_TO_THE_MARKETPLACE_REQUEST,
  TRANSFERS_TO_THE_MARKETPLACE_SUCCESS,
  TRANSFERS_TO_THE_MARKETPLACE_ERROR,
  TRANSFERS_FAILED_REQUEST,
  TRANSFERS_FAILED_SUCCESS,
  TRANSFERS_FAILED_ERROR,
  TRANSFERS_FAILED_DETAIL_REQUEST,
  TRANSFERS_FAILED_DETAIL_ERROR,
  TRANSFERS_FAILED_DETAIL_SUCCESS,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR,
  SELLERS_BY_STATUS_SUCCESS,
  SELLERS_BY_STATUS_REQUEST,
  SELLERS_BY_STATUS_ERROR,
  COMMISSION_PROGRESSION_REQUEST,
  COMMISSION_PROGRESSION_SUCCESS,
  COMMISSION_PROGRESSION_ERROR,
  ALL_TOGETHER_KPIS_REQUEST,
  ALL_TOGETHER_KPIS_SUCCESS
} from 'src/Constants/ActionTypes'

export const INITIAL_STATE = {
  period: null,
  allTogetherKPIs: {
    allRequests: false,
    success: false
  },
  transactions: {
    succeeded: {
      amount: 0,
      count: null,
      histogram: [],
      groupedBy: [],
      topHits: [],
      topHitsSellers: [],
      error: false,
      request: false
    },
    // manter failed na ordem dentro de transactions para ser o card 2
    failed: {
      request: false,
      error: null,
      amount: null,
      count: null,
      groupedBy: []
    },
    // manter averageTicket na ordem dentro de transactions para ser o card 3
    averageTicket: {
      request: false,
      error: null,
      amount: null
    },
    dispute: {
      request: false,
      error: null,
      amount: null,
      count: null,
      historic: [],
      topHits: []
    },
    // manter chargedback na ordem dentro de transactions para ser o ultimo card
    chargedBack: {
      request: false,
      error: null,
      amount: null,
      count: null,
      historic: [],
      topHits: []
    }
  },
  transfers: {
    succeeded: {
      request: false,
      error: null,
      amount: null,
      count: null,
      historic: [],
      groupedBy: []
    },
    // manter failed na ordem dentro de transactions para ser o card 2
    failed: {
      request: false,
      error: null,
      amount: null,
      count: null,
      groupedBy: []
    },
    // manter toTheMarketplace na ordem dentro de transfers para ser o ultimo card
    toTheMarketplace: {
      request: false,
      error: null,
      amount: null,
      count: null,
      historic: [],
      groupedBy: []
    }
  },
  seller: {
    transactions: {
      amount: null,
      request: false,
      error: null
    }
  },
  sellers: {
    enabled: {
      request: false,
      error: null,
      count: null
    },
    pending: {
      request: false,
      error: null,
      count: null
    },
    denied: {
      request: false,
      error: null,
      count: null
    }
  },
  commissions: {
    succeeded: {
      request: false,
      error: null,
      historic: []
    }
  }
}

export default function kpi (state = INITIAL_STATE, action) {
  switch (action.type) {
  case KPI_CHANGE_PERIOD:
    return {
      ...state,
      period: action.period
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          ...state.transactions.succeeded,
          request: true,
          error: false
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          ...state.transactions.succeeded,
          request: false,
          amount: action.data.amount,
          topHitsSellers: action.data.topHitsSellers
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          request: false,
          error: action.error
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          ...state.transactions.succeeded,
          request: true
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          ...state.transactions.succeeded,
          request: false,
          amount: action.data.amount,
          count: action.data.count,
          histogram: action.data.histogram,
          groupedBy: action.data.groupedBy,
          topHits: action.data.topHits
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_DETAIL_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        succeeded: {
          request: false,
          amount: 0,
          count: 0,
          histogram: [],
          groupedBy: [],
          topHits: [],
          error: action.error
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          ...state.transactions.chargedBack,
          request: true,
          error: null,
          amount: null
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          ...state.transactions.chargedBack,
          request: false,
          error: null,
          amount: action.data.amount
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          ...state.transactions.chargedBack,
          request: false,
          error: action.error,
          amount: null
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          request: true,
          error: null,
          amount: null,
          count: 0,
          historic: [],
          topHits: []
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          request: false,
          error: null,
          amount: action.data.amount,
          count: action.data.count,
          historic: action.data.historic,
          topHits: action.data.topHits
        }
      }
    }
  case TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        chargedBack: {
          request: false,
          error: action.error,
          amount: null,
          count: 0,
          historic: [],
          topHits: []
        }
      }
    }

  case TRANSACTIONS_DISPUTED_AMOUNT_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          ...state.transactions.dispute,
          request: true,
          error: null,
          amount: null
        }
      }
    }
  case TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          ...state.transactions.dispute,
          request: false,
          error: null,
          amount: action.data.amount
        }
      }
    }
  case TRANSACTIONS_DISPUTED_AMOUNT_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          ...state.transactions.dispute,
          request: false,
          error: action.error,
          amount: null
        }
      }
    }
  case TRANSACTIONS_DISPUTED_DETAIL_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          request: true,
          error: null,
          amount: null,
          count: 0,
          historic: [],
          topHits: []
        }
      }
    }
  case TRANSACTIONS_DISPUTED_DETAIL_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          request: false,
          error: null,
          amount: action.data.amount,
          count: action.data.count,
          historic: action.data.historic,
          topHits: action.data.topHits
        }
      }
    }
  case TRANSACTIONS_DISPUTED_DETAIL_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        dispute: {
          request: false,
          error: action.error,
          amount: null,
          count: 0,
          historic: [],
          topHits: []
        }
      }
    }
  case TRANSACTIONS_FAILED_AMOUNT_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          ...state.transactions.failed,
          request: true,
          error: null,
          amount: null
        }
      }
    }
  case TRANSACTIONS_FAILED_AMOUNT_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          ...state.transactions.failed,
          request: false,
          error: null,
          amount: action.data.amount
        }
      }
    }
  case TRANSACTIONS_FAILED_AMOUNT_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          ...state.transactions.failed,
          request: false,
          error: action.error,
          amount: null
        }
      }
    }
  case TRANSACTIONS_FAILED_DETAIL_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          request: true,
          error: null,
          amount: null,
          count: 0,
          groupedBy: []
        }
      }
    }
  case TRANSACTIONS_FAILED_DETAIL_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          request: false,
          error: null,
          amount: action.data.amount,
          count: action.data.count,
          groupedBy: action.data.groupedBy
        }
      }
    }
  case TRANSACTIONS_FAILED_DETAIL_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        failed: {
          request: false,
          error: action.error,
          amount: null,
          count: 0,
          groupedBy: []
        }
      }
    }
  case TRANSACTIONS_AVERAGE_TICKET_REQUEST:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        averageTicket: {
          request: true,
          error: null,
          amount: null
        }
      }
    }
  case TRANSACTIONS_AVERAGE_TICKET_SUCCESS:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        averageTicket: {
          request: false,
          error: null,
          amount: action.averageTicketAmount
        }
      }
    }
  case TRANSACTIONS_AVERAGE_TICKET_ERROR:
    return {
      ...state,
      transactions: {
        ...state.transactions,
        averageTicket: {
          request: false,
          error: action.error,
          amount: null
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          ...state.transfers.toTheMarketplace,
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          ...state.transfers.toTheMarketplace,
          request: false,
          error: null,
          amount: action.amount
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          ...state.transfers.toTheMarketplace,
          amount: null,
          request: false,
          error: action.error
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          ...state.transfers.toTheMarketplace,
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          request: false,
          error: null,
          ...action.data
        }
      }
    }
  case TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        toTheMarketplace: {
          amount: null,
          count: null,
          groupedBy: [],
          request: false,
          error: action.error
        }
      }
    }
  case TRANSFERS_FAILED_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_FAILED_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          amount: action.amount,
          request: false,
          error: null
        }
      }
    }
  case TRANSFERS_FAILED_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          amount: null,
          request: false,
          error: action.error
        }
      }
    }
  case TRANSFERS_FAILED_DETAIL_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          amount: null,
          count: null,
          groupedBy: [],
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_FAILED_DETAIL_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          request: false,
          error: null,
          ...action.data
        }
      }
    }
  case TRANSFERS_FAILED_DETAIL_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        failed: {
          ...state.transfers.failed,
          amount: null,
          count: null,
          groupedBy: [],
          request: false,
          error: action.error
        }
      }
    }
  case TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          ...state.transfers.succeeded,
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          ...state.transfers.succeeded,
          request: false,
          error: null,
          ...action.data
        }
      }
    }
  case TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          ...state.transfers.succeeded,
          amount: null,
          request: false,
          error: action.error
        }
      }
    }
  case TRANSFERS_SUCCEEDED_DETAIL_REQUEST:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          ...state.transfers.succeeded,
          request: true,
          error: null
        }
      }
    }
  case TRANSFERS_SUCCEEDED_DETAIL_SUCCESS:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          request: false,
          error: null,
          ...action.data
        }
      }
    }
  case TRANSFERS_SUCCEEDED_DETAIL_ERROR:
    return {
      ...state,
      transfers: {
        ...state.transfers,
        succeeded: {
          amount: null,
          count: null,
          groupedBy: [],
          historic: [],
          request: false,
          error: action.error
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST:
    return {
      ...state,
      seller: {
        transactions: {
          amount: null,
          request: true,
          error: null
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS:
    return {
      ...state,
      seller: {
        transactions: {
          request: false,
          error: null,
          amount: action.amount
        }
      }
    }
  case TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR:
    return {
      ...state,
      seller: {
        transactions: {
          amount: null,
          request: false,
          error: action.error
        }
      }
    }
  case SELLERS_BY_STATUS_REQUEST:
    return {
      ...state,
      sellers: {
        ...state.sellers,
        [action.status]: {
          ...state.sellers[action.status],
          request: true,
          error: null
        }
      }
    }
  case SELLERS_BY_STATUS_SUCCESS:
    return {
      ...state,
      sellers: {
        ...state.sellers,
        [action.status]: {
          request: false,
          error: null,
          count: action.count
        }
      }
    }
  case SELLERS_BY_STATUS_ERROR:
    return {
      ...state,
      sellers: {
        ...state.sellers,
        [action.status]: {
          count: null,
          request: false,
          error: action.error
        }
      }
    }
  case COMMISSION_PROGRESSION_REQUEST:
    return {
      ...state,
      commissions: {
        ...state.commissions,
        succeeded: {
          ...state.commissions.succeeded,
          request: true,
          error: null
        }
      }
    }
  case COMMISSION_PROGRESSION_SUCCESS:
    return {
      ...state,
      commissions: {
        ...state.commissions,
        succeeded: {
          request: false,
          error: null,
          ...action.data
        }
      }
    }
  case COMMISSION_PROGRESSION_ERROR:
    return {
      ...state,
      commissions: {
        ...state.commissions,
        succeeded: {
          historic: [],
          request: false,
          error: action.error
        }
      }
    }
  case ALL_TOGETHER_KPIS_REQUEST:
    return {
      ...state,
      allTogetherKPIs: {
        allRequests: true,
        success: false
      }
    }
  case ALL_TOGETHER_KPIS_SUCCESS:
    return {
      ...state,
      allTogetherKPIs: {
        allRequests: false,
        success: true
      }
    }
  default:
    return state
  }
}
