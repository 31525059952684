import {
  FAVORITE_MARKETPLACES_REQUEST,
  FAVORITE_MARKETPLACES_SUCCESS,
  UPDATE_FAVORITE_MARKETPLACES,
  FAVORITE_MARKETPLACES_ERROR,
  FAVORITE_SELLERS_REQUEST,
  UPDATE_FAVORITE_SELLERS,
  FAVORITE_SELLERS_SUCCESS,
  FAVORITE_SELLERS_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  favoriteSellersRequesting: false,
  favoriteSellers: [],
  favoriteSellersError: null,
  favoriteMarketplacesRequesting: false,
  favoriteMarketplaces: [],
  favoriteMarketplacesError: null
}

export default function favorites (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FAVORITE_SELLERS_REQUEST:
    return {
      ...state,
      favoriteSellersRequesting: true,
      favoriteSellers: [],
      favoriteSellersError: null
    }
  case UPDATE_FAVORITE_SELLERS:
  case FAVORITE_SELLERS_SUCCESS:
    return {
      ...state,
      favoriteSellersRequesting: false,
      favoriteSellers: action.data,
      favoriteSellersError: null
    }
  case FAVORITE_SELLERS_ERROR:
    return {
      ...state,
      favoriteSellersRequesting: false,
      favoriteSellers: [],
      favoriteSellersError: action.error
    }
  case FAVORITE_MARKETPLACES_REQUEST:
    return {
      ...state,
      favoriteMarketplacesRequesting: true,
      favoriteMarketplaces: [],
      favoriteMarketplacesError: null
    }
  case UPDATE_FAVORITE_MARKETPLACES:
  case FAVORITE_MARKETPLACES_SUCCESS:
    return {
      ...state,
      favoriteMarketplacesRequesting: false,
      favoriteMarketplaces: action.data,
      favoriteMarketplacesError: null
    }
  case FAVORITE_MARKETPLACES_ERROR:
    return {
      ...state,
      favoriteMarketplacesRequesting: false,
      favoriteMarketplaces: [],
      favoriteMarketplacesError: action.error
    }
  default:
    return state
  }
}
