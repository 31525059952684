import {
  API_SETTINGS_REQUEST,
  API_SETTINGS_SUCCESS,
  API_SETTINGS_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  items: { disablePlans: false, posPairingEnable: false, enableBoletoModal: false },
  isRequesting: false,
  requestSuccess: null,
  requestError: false
}

export default function settings (state = INITIAL_STATE, action) {
  switch (action.type) {
  case API_SETTINGS_REQUEST:
    return {
      ...state,
      isRequesting: !action.disableLoading,
      requestSuccess: false,
      requestError: null
    }
  case API_SETTINGS_SUCCESS:
    return {
      ...state,
      isRequesting: false,
      requestSuccess: true,
      requestError: null,
      items: action.settings
    }
  case API_SETTINGS_ERROR:
    return {
      ...state,
      isRequesting: false,
      requestSuccess: false,
      requestError: action.error
    }
  default:
    return state
  }
}
