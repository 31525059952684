import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { DashRoutes } from './DashRoutes'
import { MainRoutes } from './MainRoutes'
import { getRedirect } from 'src/Helpers/Router/Tools'
import { changeMarketplaceContext } from '../Actions/context'
import { useSellerContext } from '../Hooks/useSellerContext'
import NotFoundPageComponent from 'src/Components/NotFoundPage/NotFoundPageComponent'

const Routes = () => {
  useSellerContext()
  const dispatch = useDispatch()
  const history = useHistory()
  const mathRoute = useRouteMatch({ path: '/marketplace/:marketplaceId/*' })

  const {
    isLoggedIn,
    marketplace,
    user,
    isAccountCreationDisabled,
    marketplaceContextId,
    customError,
    marketplaceError
  } = useSelector(
    ({ authentication, user, marketplace, settings, context, custom }) => ({
      isLoggedIn: authentication.isLoggedIn,
      isAccountCreationDisabled: settings.items.disableAccountCreation,
      user,
      marketplace,
      marketplaceContextId: context.marketplace?.id,
      marketplaceError: marketplace.requestError,
      sellerContextId: context.seller?.id,
      customError: custom.error
    })
  )

  const [baseNameSelectedMarketplaceId, setBaseNameSelectedMarketplaceId] =
    useState()

  const marketplaceIdFromPath = mathRoute?.params?.marketplaceId

  useEffect(() => {
    let baseName = null
    if (isLoggedIn) {
      if (!marketplaceContextId && marketplaceIdFromPath) {
        dispatch(changeMarketplaceContext({ id: marketplaceIdFromPath }))
      }

      baseName = `marketplace/${marketplaceContextId || marketplace.id}`
    }

    setBaseNameSelectedMarketplaceId(baseName)
  }, [marketplaceContextId, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && marketplace.id && user.permissions) {
      let res = window.location.pathname + (getRedirect() || '')
      res && history.push(res)
    }
  }, [isLoggedIn])

  const error = marketplaceError || customError

  if (error) {
    return <NotFoundPageComponent hasSlug={false} />
  }

  return (
    <>
      {isLoggedIn ? (
        <DashRoutes
          baseNameSelectedMarketplaceId={baseNameSelectedMarketplaceId}
        />
      ) : (
        <MainRoutes isAccountCreationDisabled={isAccountCreationDisabled} />
      )}
    </>
  )
}

export { Routes }
