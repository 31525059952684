import { GET_BILLING_PAYMENT_ERROR, GET_BILLING_PAYMENT_REQUEST, GET_BILLING_PAYMENT_SUCCESS } from '../Constants/ActionTypes'

const INITIAL_STATE = {
  getBillingPaymentRequesting: false,
  billingPayment: null,
  getBillingPaymentError: null
}

export default function billingPayments (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_BILLING_PAYMENT_REQUEST:
    return {
      ...state,
      getBillingPaymentRequesting: true
    }
  case GET_BILLING_PAYMENT_SUCCESS:
    return {
      ...state,
      getBillingPaymentRequesting: false,
      billingPayment: action.billingPayment,
      getBillingPaymentError: null
    }
  case GET_BILLING_PAYMENT_ERROR:
    return {
      ...state,
      getBillingPaymentRequesting: false,
      billingPayment: null,
      getBillingPaymentError: action.error
    }
  default:
    return state
  }
}
