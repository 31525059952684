import {
  UPDATE_SELLER_DETAILS_REQUEST,
  UPDATE_SELLER_DETAILS_SUCCESS,
  UPDATE_SELLER_DETAILS_ERROR,
  CREATE_SELLER_REQUEST,
  CREATE_SELLER_SUCCESS,
  CREATE_SELLER_ERROR,
  FINAL_CREATE_SELLER_SUCCESS,
  CREATE_SELLER_RESET,
  LOGOUT_SUCCESS,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  SELLER_DETAILS_RESET,
  DELETE_SELLER_REQUEST,
  DELETE_SELLER_SUCCESS,
  DELETE_SELLER_ERROR,
  UPLOAD_SELLER_DOCUMENT_REQUEST,
  UPLOAD_SELLER_DOCUMENT_SUCCESS,
  UPLOAD_SELLER_DOCUMENT_ERROR,
  GET_SELLER_DOCUMENTS_REQUEST,
  GET_SELLER_DOCUMENTS_SUCCESS,
  GET_SELLER_DOCUMENTS_ERROR, RESET_UPLOAD_DOCUMENTS
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  createSellerRequesting: false,
  createSellerData: null,
  createSellerError: null,
  finalCreateSellerSuccess: false,
  inviteUserRequest: false,
  inviteUserSuccess: null,
  inviteUserError: null,
  deleteSellerRequest: false,
  deleteSellerSuccess: false,
  deleteSellerError: null,
  uploadSellerDocumentRequest: false,
  uploadSellerDocumentSuccess: false,
  uploadSellerDocumentError: null,
  getSellerDocumentsRequest: false,
  getSellerDocumentsSuccess: false,
  getSellerDocumentsError: null,
  documents: []
}

export default function seller (state = INITIAL_STATE, action) {
  switch (action.type) {
  case UPDATE_SELLER_DETAILS_REQUEST:
    return {
      ...state,
      isSellerDetailsRequesting: true,
      sellerDetailSuccess: false,
      sellerDetailsError: false
    }
  case UPDATE_SELLER_DETAILS_SUCCESS:
    return {
      ...state,
      isSellerDetailsRequesting: false,
      sellerDetailSuccess: true,
      seller: action.seller,
      sellerDetailsError: false
    }
  case UPDATE_SELLER_DETAILS_ERROR:
    return {
      ...state,
      isSellerDetailsRequesting: false,
      sellerDetailSuccess: false,
      sellerDetailsError: action.error
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  case CREATE_SELLER_REQUEST:
    return {
      ...state,
      createSellerRequesting: true,
      createSellerData: null,
      createSellerError: null
    }
  case CREATE_SELLER_SUCCESS:
    return {
      ...state,
      createSellerRequesting: false,
      createSellerData: action.seller,
      createSellerError: null
    }
  case CREATE_SELLER_ERROR:
    return {
      ...state,
      createSellerRequesting: false,
      createSellerData: null,
      createSellerError: action.error
    }
  case FINAL_CREATE_SELLER_SUCCESS:
    return {
      ...state,
      finalCreateSellerSuccess: true
    }
  case CREATE_SELLER_RESET:
    return {
      ...state,
      createSellerRequesting: false,
      createSellerData: null,
      createSellerError: null,
      finalCreateSellerSuccess: false
    }
  case INVITE_USER_REQUEST:
    return {
      ...state,
      inviteUserRequest: true,
      inviteUserSuccess: null,
      inviteUserError: null
    }
  case INVITE_USER_SUCCESS:
    return {
      ...state,
      inviteUserRequest: false,
      inviteUserSuccess: action.data,
      inviteUserError: null
    }
  case INVITE_USER_ERROR:
    return {
      ...state,
      inviteUserRequest: false,
      inviteUserSuccess: false,
      inviteUserError: action.error
    }
  case DELETE_SELLER_REQUEST: {
    return {
      deleteSellerRequest: true,
      deleteSellerSuccess: false,
      deleteSellerError: null
    }
  }
  case DELETE_SELLER_SUCCESS: {
    return {
      deleteSellerRequest: false,
      deleteSellerSuccess: true,
      deleteSellerError: null
    }
  }
  case DELETE_SELLER_ERROR: {
    return {
      ...state,
      deleteSellerRequest: false,
      deleteSellerSuccess: false,
      deleteSellerError: action.error
    }
  }
  case GET_SELLER_DOCUMENTS_REQUEST: {
    return {
      ...state,
      getSellerDocumentsRequest: true,
      getSellerDocumentsSuccess: false,
      getSellerDocumentsError: null
    }
  }
  case GET_SELLER_DOCUMENTS_SUCCESS: {
    return {
      ...state,
      getSellerDocumentsRequest: false,
      getSellerDocumentsSuccess: true,
      getSellerDocumentsError: null,
      documents: action.data
    }
  }
  case GET_SELLER_DOCUMENTS_ERROR: {
    return {
      ...state,
      getSellerDocumentsRequest: false,
      getSellerDocumentsSuccess: false,
      getSellerDocumentsError: action.error
    }
  }
  case UPLOAD_SELLER_DOCUMENT_REQUEST: {
    return {
      ...state,
      uploadSellerDocumentRequest: true,
      uploadSellerDocumentSuccess: undefined,
      uploadSellerDocumentError: null
    }
  }
  case UPLOAD_SELLER_DOCUMENT_SUCCESS: {
    return {
      ...state,
      uploadSellerDocumentRequest: false,
      uploadSellerDocumentSuccess: true,
      uploadSellerDocumentError: null,
      documents: [...state.documents, action.newDocument]
    }
  }
  case UPLOAD_SELLER_DOCUMENT_ERROR: {
    return {
      ...state,
      uploadSellerDocumentRequest: false,
      uploadSellerDocumentSuccess: false,
      uploadSellerDocumentError: action.error
    }
  }
  case RESET_UPLOAD_DOCUMENTS: {
    return {
      ...state,
      uploadSellerDocumentRequest: false,
      uploadSellerDocumentSuccess: false,
      uploadSellerDocumentError: null
    }
  }
  case SELLER_DETAILS_RESET:
    return {
      ...state,
      deleteSellerRequest: false,
      deleteSellerSuccess: false,
      deleteSellerError: null,
      getSellerDocumentsRequest: false,
      getSellerDocumentsSuccess: false,
      getSellerDocumentsError: null,
      documents: []
    }
  default:
    return state
  }
}
