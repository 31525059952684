import {
  ADD_WEBHOOKS_ERROR,
  ADD_WEBHOOKS_REQUEST,
  ADD_WEBHOOKS_SUCCESS,
  GET_WEBHOOKS_ERROR,
  GET_WEBHOOKS_REQUEST,
  GET_WEBHOOKS_SUCCESS,
  GET_WEBHOOKS_EVENTS_REQUEST,
  GET_WEBHOOKS_EVENTS_SUCCESS,
  GET_WEBHOOKS_EVENTS_ERROR,
  REMOVE_WEBHOOK_ERROR,
  REMOVE_WEBHOOK_REQUEST,
  REMOVE_WEBHOOK_SUCCESS,
  RESET_WEBHOOK
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  getWebhooksRequesting: false,
  webhooks: [],
  getWebhooksError: null,
  totalPages: 1,

  getWebhooksEventsRequesting: false,
  webhooksEvents: [],
  getWebhooksEventsError: null,

  addWebhooksRequesting: false,
  addWebhooksSuccess: false,
  addWebhooksError: null,

  removeWebhookRequesting: false,
  removeWebhookSuccess: null,
  removeWebhookError: null
}

export default function webhooks (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_WEBHOOKS_REQUEST:
    return {
      ...state,
      webhooks: [],
      getWebhooksRequesting: true,
      getWebhooksError: null
    }
  case GET_WEBHOOKS_SUCCESS:
    return {
      ...state,
      getWebhooksRequesting: false,
      webhooks: action.data,
      getWebhooksError: null,
      totalPages: action.totalPages
    }
  case GET_WEBHOOKS_ERROR:
    return {
      ...state,
      getWebhooksRequesting: false,
      webhooks: [],
      getWebhooksError: action.error
    }
  case GET_WEBHOOKS_EVENTS_REQUEST:
    return {
      ...state,
      webhooksEvents: [],
      getWebhooksEventsRequesting: true,
      getWebhooksEventsError: null
    }
  case GET_WEBHOOKS_EVENTS_SUCCESS:
    return {
      ...state,
      getWebhooksEventsRequesting: false,
      webhooksEvents: action.data,
      getWebhooksEventsError: null,
      totalPages: action.totalPages
    }
  case GET_WEBHOOKS_EVENTS_ERROR:
    return {
      ...state,
      getWebhooksEventsRequesting: false,
      webhooksEvents: [],
      getWebhooksEventsError: action.error
    }
  case ADD_WEBHOOKS_REQUEST:
    return {
      ...state,
      addWebhooksRequesting: true,
      addWebhooksSuccess: false,
      addWebhooksError: null
    }
  case ADD_WEBHOOKS_SUCCESS:
    return {
      ...state,
      addWebhooksRequesting: false,
      addWebhooksSuccess: true,
      addWebhooksError: null
    }
  case ADD_WEBHOOKS_ERROR:
    return {
      ...state,
      addWebhooksRequesting: false,
      addWebhooksSuccess: false,
      addWebhooksError: action.error
    }
  case REMOVE_WEBHOOK_REQUEST:
    return {
      ...state,
      removeWebhookRequesting: true,
      removeWebhookSuccess: null
    }
  case REMOVE_WEBHOOK_SUCCESS:
    return {
      ...state,
      removeWebhookRequesting: false,
      removeWebhookSuccess: true,
      removeWebhookError: null
    }
  case REMOVE_WEBHOOK_ERROR:
    return {
      ...state,
      removeWebhookRequesting: false,
      removeWebhookSuccess: false,
      removeWebhookError: action.error
    }
  case RESET_WEBHOOK:
    return {
      ...state,
      addWebhooksRequesting: false,
      addWebhooksSuccess: false,
      addWebhooksError: null,
      removeWebhookRequesting: false,
      removeWebhookSuccess: null,
      removeWebhookError: null
    }
  default:
    return state
  }
}
