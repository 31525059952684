import {
  RESUME_REQUEST,
  RESUME_SUCCESS,
  RESUME_ERROR,
  RESUME_TABLE_REQUEST,
  RESUME_TABLE_SUCCESS,
  RESUME_TABLE_ERROR, LOGOUT_SUCCESS, RESET_RESUME
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  resumedData: {
    numberOfSales: 0,
    totalAmount: 0,
    totalFees: 0,
    totalNetAmount: 0
  },
  tableData: [],
  isResumeTableRequesting: false,
  resumeTableSuccess: null,
  resumeTableError: null,
  isResumeRequesting: false,
  resumeSuccess: null,
  resumeError: false
}

export default function resume (state = INITIAL_STATE, action) {
  switch (action.type) {
  case RESUME_REQUEST:
    return {
      ...state,
      isResumeRequesting: true,
      resumeError: null
    }
  case RESUME_SUCCESS:
    return {
      ...state,
      isResumeRequesting: false,
      resumeSuccess: true,
      resumeError: null,
      resumedData: action.resumedData
    }
  case RESUME_ERROR:
    return {
      ...INITIAL_STATE,
      isResumeRequesting: false,
      resumeSuccess: false,
      resumeError: action.error
    }
  case RESUME_TABLE_REQUEST:
    return {
      ...state,
      isResumeTableRequesting: true,
      resumeTableError: null
    }
  case RESUME_TABLE_SUCCESS:
    return {
      ...state,
      isResumeTableRequesting: false,
      resumeTableSuccess: true,
      resumeTableError: null,
      tableData: action.tableData
    }
  case RESUME_TABLE_ERROR:
    return {
      ...INITIAL_STATE,
      isResumeTableRequesting: false,
      resumeTableSuccess: false,
      resumeTableError: action.error
    }
  case LOGOUT_SUCCESS:
  case RESET_RESUME:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
