import {
  PAIR_POS_ERROR,
  PAIR_POS_REQUEST,
  PAIR_POS_SUCCESS,
  RESET_POS
} from 'src/Constants/ActionTypes'

const INITIAL_STATE = {
  pairPOSRequesting: false,
  pairPOSSuccess: null,
  pairPOSError: null
}

export default function pos (state = INITIAL_STATE, action) {
  switch (action.type) {
  case PAIR_POS_REQUEST:
    return {
      ...state,
      pairPOSRequesting: true,
      pairPOSSuccess: null,
      pairPOSError: null
    }
  case PAIR_POS_SUCCESS:
    return {
      ...state,
      pairPOSRequesting: false,
      pairPOSSuccess: true,
      pairPOSError: null
    }
  case PAIR_POS_ERROR:
    return {
      ...state,
      pairPOSRequesting: false,
      pairPOSSuccess: false,
      pairPOSError: action.error
    }
  case RESET_POS:
    return INITIAL_STATE
  default:
    return state
  }
}
