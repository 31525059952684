import {
  CREATE_EXPORT_ERROR,
  CREATE_EXPORT_REQUEST,
  CREATE_EXPORT_SUCCESS,
  EXPORT_LIST_ERROR,
  EXPORT_LIST_REQUEST,
  EXPORT_LIST_SUCCESS,
  LOGOUT_SUCCESS,
  PROGRESS_EXPORT_SUCCESS,
  RESET_CREATE_EXPORT,
  RESET_WATCH_FILES,
  CONTROL_EXPORT_NOTIFICATION,
  REDO_EXPORT_REQUEST,
  REDO_EXPORT_SUCCESS,
  REDO_EXPORT_FAILURE,
  DELETE_EXPORT_REQUEST,
  DELETE_EXPORT_SUCCESS,
  DELETE_EXPORT_FAILURE,
  RESET_ALL_FILES_REQUEST,
  RESET_ALL_FILES_SUCCESS,
  DOWNLOAD_EXPORT_REQUEST,
  DOWNLOAD_EXPORT_SUCCESS,
  DOWNLOAD_EXPORT_ERROR,
  DOWNLOAD_ALL_FILES_REQUEST,
  DOWNLOAD_ALL_FILES_SUCCESS
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  createExportRequesting: false,
  createExportSuccess: null,
  createExportError: null, // obj de erro na criação do relatório
  totalPages: 1,
  totalItems: 0,
  exportsList: [],
  exportsListRequesting: false,
  exportsListSuccess: null,
  errorList: [], // lista de arquivos com erro no status (-1) para re-tentativa
  exportsListError: null, // obj de erro na solicitação da lista de exportação
  showRequestNotification: false,
  watchFiles: [],
  showReportModal: false,
  redoFileExportRequest: false,
  redoFilenameExport: null,
  redoFileExportError: null,
  deleteFileExportRequest: false,
  deleteFileExport: null,
  deleteFileExportError: null,
  resetAllFilesRequest: false,
  downloadFileRequest: false,
  downloadFileError: null,
  downloadAllFilesRequest: false
}

export default function exports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_CREATE_EXPORT:
      return {
        ...state,
        createExportRequesting: false,
        createExportSuccess: null,
        createExportError: null
      }
    case CREATE_EXPORT_REQUEST:
      return {
        ...state,
        createExportRequesting: true,
        createExportSuccess: null,
        createExportError: null
      }
    case CREATE_EXPORT_SUCCESS:
      return {
        ...state,
        createExportRequesting: false,
        createExportSuccess: true,
        createExportError: null,
        watchFiles: [action.data, ...state.watchFiles],
        showReportModal: true
      }
    case CREATE_EXPORT_ERROR:
      return {
        ...state,
        createExportRequesting: false,
        createExportSuccess: false,
        createExportError: action.error,
        showReportModal: true
      }
    case EXPORT_LIST_REQUEST:
      return {
        ...state,
        exportsListRequesting: true,
        exportsListSuccess: null,
        exportsListError: null
      }
    case EXPORT_LIST_SUCCESS:
      return {
        ...state,
        exportsListRequesting: false,
        exportsListSuccess: true,
        exportsList: action.exportsList,
        exportsListError: null,
        watchFiles: action.progressList,
        totalPages: action.totalPages,
        totalItems: action.totalItems,
        errorList: action.errorList
      }
    case PROGRESS_EXPORT_SUCCESS:
      let watchFiles = state.watchFiles
      let exportsList = state.exportsList
      let errorList = state.errorList

      if (action.file.status === 2) {
        const filteredItems = watchFiles.filter(
          (file) => file.filename !== action.file.filename
        )
        const existFile = !!exportsList.find(
          (file) => file.filename === action.file.filename
        )
        watchFiles = filteredItems
        if (!existFile) {
          exportsList.push(action.file)
        }
      } else if (action.file.status === -1) {
        const filteredItems = watchFiles.filter(
          (file) => file.filename !== action.file.filename
        )
        watchFiles = filteredItems
        errorList.push(action.file)
      } else {
        const updateItems = watchFiles.map((file) => {
          if (file.filename === action.file.filename) {
            file.status = action.file.status
            file.size = action.file.size
            file.progress = action.file.progress
            file.total = action.file.total
            file.percentage = action.file.percentage
            file.url = action.file.url
            file.type = action.file.type

            return file
          } else {
            return file
          }
        })
        watchFiles = updateItems
      }

      return {
        ...state,
        watchFiles,
        exportsList,
        errorList,
        showReportModal: action.file.status === 2 ? true : state.showReportModal
      }
    case EXPORT_LIST_ERROR:
      return {
        ...state,
        exportsListRequesting: false,
        exportsListSuccess: false,
        exportsListError: action.error
      }
    case DOWNLOAD_EXPORT_REQUEST:
      return {
        ...state,
        downloadFileRequest: true,
        downloadFileError: null
      }
    case DOWNLOAD_EXPORT_SUCCESS:
      return {
        ...state,
        downloadFileRequest: false,
        downloadFileError: null,
        exportsList: state.exportsList.filter(
          (file) => file.filename !== action.filename
        )
      }
    case DOWNLOAD_EXPORT_ERROR:
      return {
        ...state,
        downloadFileRequest: false,
        downloadFileError: action.error
      }
    case DOWNLOAD_ALL_FILES_REQUEST:
      return {
        ...state,
        downloadAllFilesRequest: true
      }
    case DOWNLOAD_ALL_FILES_SUCCESS:
      return {
        ...state,
        downloadAllFilesRequest: false
      }
    case CONTROL_EXPORT_NOTIFICATION:
      return {
        ...state,
        showReportModal: action.value
      }
    case RESET_WATCH_FILES:
      return {
        ...state,
        watchFiles: []
      }
    case LOGOUT_SUCCESS:
      return INITIAL_STATE
    case REDO_EXPORT_REQUEST:
      return {
        ...state,
        redoFileExportRequest: true,
        redoFilenameExport: action.filename,
        redoFileExportError: null
      }
    case REDO_EXPORT_SUCCESS:
      return {
        ...state,
        redoFileExportRequest: false,
        redoFilenameExport: null,
        redoFileExportError: null,
        watchFiles: [action.data, ...state.watchFiles],
        showReportModal: true,
        errorList: state.errorList.filter(
          (file) => file.filename !== action.filename
        )
      }
    case REDO_EXPORT_FAILURE:
      return {
        ...state,
        redoFileExportRequest: false,
        redoFilenameExport: null,
        redoFileExportError: action.error
      }
    case DELETE_EXPORT_REQUEST:
      return {
        ...state,
        deleteFileExportRequest: true,
        deleteFileExport: action.filename,
        deleteFileExportError: null
      }
    case DELETE_EXPORT_SUCCESS:
      return {
        ...state,
        deleteFileExportRequest: false,
        deleteFileExport: action.filename,
        deleteFileExportError: null,
        errorList: state.errorList.filter(
          (file) => file.filename !== action.filename
        ),
        exportsList: state.exportsList.filter(
          (file) => file.filename !== action.filename
        )
      }
    case DELETE_EXPORT_FAILURE:
      return {
        ...state,
        deleteFileExportRequest: false,
        deleteFileExport: null,
        deleteFileExportError: action.error
      }
    case RESET_ALL_FILES_REQUEST:
      return {
        ...state,
        resetAllFilesRequest: true
      }
    case RESET_ALL_FILES_SUCCESS:
      return {
        ...state,
        resetAllFilesRequest: false
      }
    default:
      return state
  }
}
