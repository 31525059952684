import {
  MARKETPLACES_ACCESS_REQUEST,
  MARKETPLACES_ACCESS_SUCCESS,
  MARKETPLACES_ACCESS_ERROR,
  LOGOUT_SUCCESS
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  marketplaces: null,
  isRequesting: false,
  requestSuccess: null,
  requestError: false
}

export default function marketplacesAccess (state = INITIAL_STATE, action) {
  switch (action.type) {
  case MARKETPLACES_ACCESS_REQUEST:
    return {
      ...state,
      isRequesting: true,
      requestSuccess: false,
      requestError: null
    }
  case MARKETPLACES_ACCESS_SUCCESS:
    return {
      ...state,
      isRequesting: false,
      requestSuccess: true,
      requestError: null,
      marketplaces: action.marketplaces
    }
  case MARKETPLACES_ACCESS_ERROR:
    return {
      ...INITIAL_STATE,
      isRequesting: false,
      requestSuccess: false,
      requestError: action.error
    }
  case LOGOUT_SUCCESS:
    return INITIAL_STATE
  default:
    return state
  }
}
