import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_ERROR, LOGOUT_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASS_REQUEST,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_ERROR,
  RESET_USER,
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_ERROR,
  DELETE_USER_PROFILE_REQUEST,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERROR,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_ERROR, RESET_UPDATE_USER_PASS
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  id: null,
  username: null,
  firstName: null,
  lastName: null,
  permissions: null,
  requireEmailConfirm: null,
  isRequesting: false,
  requestSuccess: false,
  requestError: false,

  updateUserRequesting: false,
  updateUserSuccess: false,
  updateUserError: null,

  updatePassRequesting: false,
  updatePassSuccess: false,
  updatePassError: null,

  usersListRequesting: false,
  usersList: [],
  totalPages: 1,
  usersListError: null,

  userDeleteSuccess: false,
  userDeleteRequesting: false,
  userDeleteError: null,

  updatePermissionsRequesting: false,
  updatePermissionsSuccess: null,
  updatePermissionsError: null,

  userDetail: null,
  totalPagesUserDetail: 1,
  userDetailRequesting: false,
  userDetailError: null,

  userPermissionsRequesting: false,
  userPermissions: null,
  userPermissionsError: null
}

export default function user (state = INITIAL_STATE, action) {
  switch (action.type) {
  case USER_REQUEST:
    return { ...state, requestError: null, isRequesting: true }
  case USER_SUCCESS:
    return {
      ...state,
      id: action.id,
      firstName: action.firstName,
      lastName: action.lastName,
      username: action.username,
      permissions: action.permissions,
      requireEmailConfirm: action.requireEmailConfirm,
      isRequesting: false,
      requestSuccess: true,
      requestError: null
    }
  case USER_ERROR:
    return {
      ...INITIAL_STATE,
      requestError: action.error,
      requestSuccess: false,
      isRequesting: false
    }

  case UPDATE_USER_REQUEST:
    return {
      ...state,
      updateUserRequesting: true,
      updateUserSuccess: false,
      updateUserError: null
    }
  case UPDATE_USER_SUCCESS:
    return {
      ...state,
      firstName: action.firstName,
      lastName: action.lastName,
      updateUserRequesting: false,
      updateUserSuccess: true,
      updateUserError: null
    }
  case UPDATE_USER_ERROR:
    return {
      ...state,
      updateUserRequesting: null,
      updateUserSuccess: false,
      updateUserError: action.error
    }

  case UPDATE_PASS_REQUEST:
    return {
      ...state,
      updatePassRequesting: true,
      updatePassSuccess: false,
      updatePassError: null
    }
  case UPDATE_PASS_SUCCESS:
    return {
      ...state,
      updatePassRequesting: false,
      updatePassSuccess: true,
      updatePassError: null
    }
  case UPDATE_PASS_ERROR:
    return {
      ...state,
      updatePassRequesting: null,
      updatePassSuccess: false,
      updatePassError: action.error
    }
  case RESET_UPDATE_USER_PASS:
    return {
      ...state,
      updatePassRequesting: false,
      updatePassSuccess: false,
      updatePassError: null
    }

  case RESET_USER:
    return INITIAL_STATE

  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  case LIST_USERS_REQUEST:
    return {
      ...state,
      usersListRequesting: true,
      usersListError: null
    }
  case LIST_USERS_SUCCESS:
    return {
      ...state,
      usersList: action.usersList,
      totalPages: action.totalPages,
      usersListRequesting: false,
      usersListError: null
    }
  case LIST_USERS_ERROR:
    return {
      ...state,
      usersList: [],
      usersListRequesting: false,
      usersListError: action.error
    }
  case DELETE_USER_PROFILE_REQUEST:
    return {
      ...state,
      userDeleteSuccess: null,
      userDeleteRequesting: true,
      userDeleteError: null
    }
  case DELETE_USER_PROFILE_SUCCESS:
    return {
      ...state,
      userDeleteSuccess: true,
      userDeleteRequesting: false,
      userDeleteError: null
    }
  case DELETE_USER_PROFILE_ERROR:
    return {
      ...state,
      userDeleteSuccess: null,
      userDeleteRequesting: false,
      userDeleteError: action.error
    }
  case UPDATE_PERMISSION_REQUEST:
    return {
      ...state,
      usersList: (() => {
        const index = state.usersList.findIndex(user => action.userId === user.id)
        const user = state.usersList[index]
        return [
          ...state.usersList.slice(0, index),
          {
            ...user,
            profile: {
              ...user.profile,
              dashboard_layout: action.profile.value
            }
          },
          ...state.usersList.slice(index + 1)
        ]
      })(),
      updatePermissionsRequesting: true,
      updatePermissionsSuccess: null,
      updatePermissionsError: null
    }
  case UPDATE_PERMISSION_SUCCESS:
    return {
      ...state,
      updatePermissionsSuccess: action.data,
      updatePermissionsRequesting: false,
      updatePermissionsError: null
    }
  case UPDATE_PERMISSION_ERROR:
    return {
      ...state,
      updatePermissionsSuccess: false,
      updatePermissionsRequesting: false,
      updatePermissionsError: action.error
    }
  case USER_DETAIL_REQUEST:
    return {
      ...state,
      userDetail: null,
      totalPagesUserDetail: 1,
      userDetailRequesting: true,
      userDetailError: null
    }
  case USER_DETAIL_SUCCESS:
    return {
      ...state,
      userDetail: action.userDetail,
      totalPagesUserDetail: action.totalPages,
      userDetailRequesting: false,
      userDetailError: null
    }
  case USER_DETAIL_ERROR:
    return {
      ...state,
      userDetail: null,
      totalPagesUserDetail: 1,
      userDetailRequesting: false,
      userDetailError: action.error
    }
  case PERMISSION_REQUEST:
    return {
      ...state,
      userPermissionsRequesting: true,
      userPermissions: null,
      userPermissionsError: null
    }
  case PERMISSION_SUCCESS:
    return {
      ...state,
      userPermissions: action.userPermissions,
      userPermissionsRequesting: false,
      userPermissionsError: null
    }
  case PERMISSION_ERROR:
    return {
      ...state,
      userPermissions: null,
      userPermissionsRequesting: false,
      userPermissionsError: action.error
    }
  default:
    return state
  }
}
