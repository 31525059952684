import {
  RESET_SELLERS,
  GET_SELLERS_ERROR,
  GET_SELLERS_REQUEST,
  GET_SELLERS_SUCCESS,
  GET_SELLERS_SEARCH_REQUEST,
  GET_SELLERS_SEARCH_SUCCESS,
  GET_SELLERS_SEARCH_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  sellersRequesting: false,
  sellers: [],
  sellersError: null,
  sellersTotalPages: 0,
  sellersPageSize: 100,
  sellersSearchRequesting: false,
  sellersSearch: [],
  sellersSearchCount: null,
  sellersSearchError: null,
  sellersTotalItems: 0
}

export default function sellers (state = INITIAL_STATE, action) {
  switch (action.type) {
  case RESET_SELLERS:
    return {
      ...state,
      sellers: []
    }
  case GET_SELLERS_REQUEST:
    return {
      ...state,
      sellersRequesting: true,
      sellersError: null
    }
  case GET_SELLERS_SUCCESS:
    return {
      ...state,
      sellersRequesting: false,
      sellersError: null,
      sellers: action.data,
      sellersTotalPages: action.totalPages,
      sellersTotalItems: action.totalItems,
      sellersPageSize: action.limit
    }
  case GET_SELLERS_ERROR:
    return {
      ...state,
      sellersRequesting: false,
      sellersError: action.error,
      sellersTotalItems: 0,
      sellersTotalPages: 1,
      sellers: []
    }
  case GET_SELLERS_SEARCH_REQUEST:
    return {
      ...state,
      sellersSearchRequesting: true,
      sellersSearch: [],
      sellersSearchCount: null,
      sellersSearchError: null
    }
  case GET_SELLERS_SEARCH_SUCCESS:
    return {
      ...state,
      sellersSearchRequesting: false,
      sellersSearch: action.data,
      sellersSearchCount: action.sellersSearchCount,
      sellersSearchError: null
    }
  case GET_SELLERS_SEARCH_ERROR:
    return {
      ...state,
      sellersSearchRequesting: false,
      sellersSearch: [],
      sellersSearchError: action.error
    }
  default:
    return state
  }
}
