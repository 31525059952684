import {
  LIST_TRANSACTIONS_SUCCESS,
  LIST_TRANSACTIONS_ERROR,
  LIST_TRANSACTIONS_REQUEST,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_BY_ID_ERROR,
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_RECEIVABLES_REQUEST,
  GET_RECEIVABLES_SUCCESS,
  GET_RECEIVABLES_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_REQUEST,
  VOID_TRANSACTION_SUCCESS,
  VOID_TRANSACTION_ERROR,
  VOID_TRANSACTION_REQUEST,
  CANCEL_BOLETO_TRANSACTION_SUCCESS,
  CANCEL_BOLETO_TRANSACTION_ERROR,
  CANCEL_BOLETO_TRANSACTION_REQUEST,
  CAPTURE_TRANSACTION_SUCCESS,
  CAPTURE_TRANSACTION_ERROR,
  CAPTURE_TRANSACTION_REQUEST,
  LOGOUT_SUCCESS,
  RESET_TRANSACTION,
  RESET_TRANSACTION_ACTIONS,
  GET_TRANSACTION_CANCELLED_LETTER_REQUEST,
  GET_TRANSACTION_CANCELLED_LETTER_SUCCESS,
  GET_TRANSACTION_CANCELLED_LETTER_ERROR
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  transactionsRequesting: false,
  transactionsError: null,
  transactions: [],
  transactionsTotalPages: 1,
  transactionsPageSize: 100,
  transactionsTotalItems: 0,

  receivables: null,
  receivablesRequesting: false,
  receivablesError: null,
  receivablesTotalItems: 0,

  transactionCreated: null,
  isCreationRequesting: false,
  transactionCreationSuccess: false,
  transactionCreationError: null,

  transactionRequesting: false,
  transactionError: null,
  transaction: null,

  voidTransactionRequesting: false,
  voidTransactionError: null,
  voidTransactionAmount: null,

  cancelBoletoTransactionRequesting: false,
  cancelBoletoTransactionError: null,
  cancelBoletoTransactionSuccess: false,

  captureTransactionRequesting: false,
  captureTransactionError: null,
  captureTransactionAmount: null,

  getTransactionCancelledLetterRequesting: false,
  getTransactionCancelledLetterSuccess: null,
  getTransactionCancelledLetterError: null
}

export default function transactions (state = INITIAL_STATE, action) {
  switch (action.type) {
  case LIST_TRANSACTIONS_REQUEST:
    return {
      ...state,
      transactionsRequesting: true,
      transactionsError: null
    }
  case LIST_TRANSACTIONS_SUCCESS:
    const transactions = action.transactions.map((transaction) => {
      const { amount, fees } = transaction
      const netAmount = amount - fees
      transaction.net_amount = netAmount
      return transaction
    })

    return {
      ...state,
      transactionsRequesting: false,
      transactionsError: null,
      transactions,
      transactionsTotalPages: action.totalPages,
      transactionsTotalItems: action.totalItems,
      transactionsPageSize: action.limit
    }
  case LIST_TRANSACTIONS_ERROR:
    return {
      ...state,
      transactionsRequesting: false,
      transactionsError: action.error,
      transactionsTotalItems: 0,
      transactions: [],
      transactionsTotalPages: 1,
      transactionsPageSize: 100
    }
  case GET_TRANSACTION_BY_ID_REQUEST:
    return {
      ...state,
      transactionRequesting: true,
      transactionError: null
    }
  case GET_TRANSACTION_BY_ID_SUCCESS:
    return {
      ...state,
      transactionRequesting: false,
      transactionError: null,
      transaction: action.transaction
    }
  case GET_TRANSACTION_BY_ID_ERROR:
    return {
      ...state,
      transactionRequesting: false,
      transactionError: action.error
    }
  case GET_RECEIVABLES_REQUEST:
    return {
      ...state,
      receivablesRequesting: true,
      receivablesError: null,
      receivablesTotalItems: 0
    }
  case GET_RECEIVABLES_SUCCESS:
    return {
      ...state,
      receivablesRequesting: false,
      receivablesError: null,
      receivables: action.receivables,
      receivablesTotalItems: action.totalItems
    }
  case GET_RECEIVABLES_ERROR:
    return {
      ...state,
      receivablesRequesting: false,
      receivablesError: action.error
    }
  case CREATE_TRANSACTION_REQUEST:
    return {
      ...state,
      isCreationRequesting: true,
      transactionCreationSuccess: false,
      transactionCreationError: null
    }
  case CREATE_TRANSACTION_SUCCESS:
    return {
      ...state,
      isCreationRequesting: false,
      transactionCreationSuccess: true,
      transactionCreationError: null,
      transactionCreated: action.transaction
    }
  case CREATE_TRANSACTION_ERROR:
    return {
      ...INITIAL_STATE,
      isCreationRequesting: false,
      transactionCreationSuccess: false,
      transactionCreationError: action.error
    }
  case VOID_TRANSACTION_REQUEST:
    return {
      ...state,
      voidTransactionRequesting: true,
      voidTransactionAmount: null,
      voidTransactionError: null
    }
  case VOID_TRANSACTION_SUCCESS:
    return {
      ...state,
      voidTransactionRequesting: false,
      voidTransactionAmount: action.amount,
      voidTransactionError: null
    }
  case VOID_TRANSACTION_ERROR:
    return {
      ...state,
      voidTransactionRequesting: false,
      voidTransactionAmount: null,
      voidTransactionError: action.error
    }
  case CANCEL_BOLETO_TRANSACTION_REQUEST:
    return {
      ...state,
      cancelBoletoTransactionRequesting: true,
      cancelBoletoTransactionSuccess: false,
      cancelBoletoTransactionError: null
    }
  case CANCEL_BOLETO_TRANSACTION_SUCCESS:
    return {
      ...state,
      cancelBoletoTransactionRequesting: false,
      cancelBoletoTransactionSuccess: true,
      cancelBoletoTransactionError: null
    }
  case CANCEL_BOLETO_TRANSACTION_ERROR:
    return {
      ...state,
      cancelBoletoTransactionRequesting: false,
      cancelBoletoTransactionSuccess: false,
      cancelBoletoTransactionError: action.error
    }
  case CAPTURE_TRANSACTION_REQUEST:
    return {
      ...state,
      captureTransactionRequesting: true,
      captureTransactionAmount: null,
      captureTransactionError: null
    }
  case CAPTURE_TRANSACTION_SUCCESS:
    return {
      ...state,
      captureTransactionRequesting: false,
      captureTransactionAmount: action.amount,
      captureTransactionError: null
    }
  case CAPTURE_TRANSACTION_ERROR:
    return {
      ...state,
      captureTransactionRequesting: false,
      captureTransactionAmount: null,
      captureTransactionError: action.error
    }
  case GET_TRANSACTION_CANCELLED_LETTER_REQUEST:
    return {
      ...state,
      getTransactionCancelledLetterRequesting: true
    }
  case GET_TRANSACTION_CANCELLED_LETTER_SUCCESS:
    return {
      ...state,
      getTransactionCancelledLetterRequesting: false,
      getTransactionCancelledLetterSuccess: true,
      getTransactionCancelledLetterError: null
    }
  case GET_TRANSACTION_CANCELLED_LETTER_ERROR:
    return {
      ...state,
      getTransactionCancelledLetterRequesting: false,
      getTransactionCancelledLetterSuccess: false,
      getTransactionCancelledLetterError: action.error
    }
  case RESET_TRANSACTION:
    return {
      ...state,
      transaction: null,
      receipt: null,
      receivables: null
    }
  case RESET_TRANSACTION_ACTIONS:
    return {
      ...state,
      voidTransactionError: null,
      voidTransactionAmount: null,
      captureTransactionError: null,
      captureTransactionAmount: null,
      cancelBoletoTransactionError: null,
      cancelBoletoTransactionSuccess: false
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
