import { GET_BANKS_FAILURE, GET_BANKS_REQUEST, GET_BANKS_SUCCESS } from '../Constants/ActionTypes'

const INITIAL_STATE = {
  getBanksRequesting: false,
  banks: [],
  getBanksError: null
}

export default function banks (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_BANKS_REQUEST:
    return {
      ...state,
      getBanksRequesting: true
    }
  case GET_BANKS_SUCCESS:
    return {
      ...state,
      getBanksRequesting: false,
      banks: action.banks,
      getBanksError: null
    }
  case GET_BANKS_FAILURE:
    return {
      ...state,
      getBanksRequesting: false,
      banks: [],
      getBanksError: action.error
    }
  default:
    return state
  }
}
