import {
  GET_FUTURE_TRANSFERS_LIST_ERROR,
  GET_FUTURE_TRANSFERS_LIST_REQUEST,
  GET_FUTURE_TRANSFERS_LIST_SUCCESS,
  NEXT_FUTURE_TRANSFER_ERROR,
  NEXT_FUTURE_TRANSFER_REQUEST,
  NEXT_FUTURE_TRANSFER_SUCCESS,
  RESET_FUTURE_TRANSFERS,
  TOTAL_FUTURE_TRANSFERS_ERROR,
  TOTAL_FUTURE_TRANSFERS_REQUEST,
  TOTAL_FUTURE_TRANSFERS_SUCCESS
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  futureTransfersRequesting: false,
  futureTransfersError: {
    total: null,
    sevenDays: null,
    fifteenDays: null,
    thirtyDays: null
  },

  nextFutureTransferRequesting: true,
  nextFutureTransfer: null,
  nextFutureTransferError: null,

  futureTransfersListRequesting: false,
  futureTransfersList: [],
  futureTransfersListError: null,
  futureTransfersPageSize: 100,
  futureTransferListTotalPages: 1,
  futureTransferListTotalItems: 0,
  totalAmountRequesting: true,
  totalAmount: null,
  totalAmountError: null
}

export default function futureTransfers (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_FUTURE_TRANSFERS_LIST_REQUEST:
    return {
      ...state,
      futureTransfersListRequesting: true,
      futureTransfersListTotalPages: 1
    }
  case GET_FUTURE_TRANSFERS_LIST_SUCCESS:
    return {
      ...state,
      futureTransfersListRequesting: false,
      futureTransfersList: action.data,
      futureTransfersListTotalPages: action.totalPages,
      futureTransfersListTotalItems: action.total,
      getFutureTransfersListError: null,
      futureTransfersPageSize: action.limit
    }
  case GET_FUTURE_TRANSFERS_LIST_ERROR:
    return {
      ...state,
      futureTransfersListRequesting: false,
      futureTransfersList: [],
      futureTransfersListTotalItems: 0,
      futureTransfersListError: action.error,
      futureTransfersPageSize: 100
    }
  case NEXT_FUTURE_TRANSFER_REQUEST:
    return {
      ...state,
      nextFutureTransferRequesting: true,
      nextFutureTransfer: null,
      nextFutureTransferError: null
    }
  case NEXT_FUTURE_TRANSFER_SUCCESS:
    return {
      ...state,
      nextFutureTransferRequesting: false,
      nextFutureTransfer: action.nextFutureTransfer,
      nextFutureTransferError: null
    }
  case NEXT_FUTURE_TRANSFER_ERROR:
    return {
      ...state,
      nextFutureTransferRequesting: false,
      nextFutureTransfer: null,
      nextFutureTransferError: action.error
    }
  case TOTAL_FUTURE_TRANSFERS_REQUEST:
    return {
      ...state,
      totalAmountRequesting: true,
      totalAmount: null,
      totalAmountError: null
    }
  case TOTAL_FUTURE_TRANSFERS_SUCCESS:
    return {
      ...state,
      totalAmountRequesting: false,
      totalAmount: action.totalAmount,
      totalAmountError: null
    }
  case TOTAL_FUTURE_TRANSFERS_ERROR:
    return {
      ...state,
      totalAmountRequesting: false,
      totalAmount: null,
      totalAmountError: action.error
    }
  case RESET_FUTURE_TRANSFERS:
    return {
      ...state,
      futureTransfersListRequesting: false,
      futureTransfersList: [],
      futureTransfersListError: null,
      futureTransfersPageSize: 100,
      futureTransferListTotalPages: 1,
      futureTransferListTotalItems: 0
    }
  default:
    return state
  }
}
