import {
  GET_PLAN_DETAILS_ERROR,
  GET_PLAN_DETAILS_REQUEST,
  GET_PLAN_DETAILS_SUCCESS,
  GET_PLANS_ERROR,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_SELLERS_BY_PLAN_REQUEST,
  GET_SELLERS_BY_PLAN_SUCCESS,
  GET_SELLERS_BY_PLAN_ERROR,
  GET_SELLERS_BY_PLAN_INFINTY_REQUEST,
  GET_SELLERS_BY_PLAN_INFINTY_SUCCESS,
  GET_SELLERS_BY_PLAN_INFINTY_ERROR,
  SET_SELLER_PLAN_SUCCESS,
  SET_SELLER_PLAN_RESET,
  RESET_PLAN_DETAILS,
  RESET_PLANS,
  RESET_SELLERS_PLAN,
  GET_SELLER_ACTIVE_PLAN_REQUEST,
  GET_SELLER_ACTIVE_PLAN_SUCCESS,
  GET_SELLER_ACTIVE_PLAN_ERROR,
  SET_SELLER_PLAN_REQUEST,
  SET_SELLER_PLAN_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  plansRequesting: false,
  plans: [],
  plansError: null,
  totalPages: 1,
  planDetailsRequesting: false,
  planDetailsData: {},
  planDetailsSuccess: false,
  planDetailsError: null,
  sellersPlanRequesting: false,
  sellersPlanInfinityRequesting: false,
  sellersPlan: [],
  totalPagesSellers: 1,
  totalSellers: 0,
  sellersPlanError: null,
  setSellerPlanRequesting: false,
  setSellerPlanSuccess: false,
  setSellerPlanError: null,
  sellerActivePlanRequesting: false,
  sellerActivePlanSuccess: false,
  sellerActivePlanError: null,
  sellerActivePlan: null
}

export default function planDetails (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_PLANS_REQUEST:
    return {
      ...state,
      plansRequesting: true,
      plansError: null,
      planDetailsRequesting: false,
      planDetailsData: {},
      planDetailsSuccess: false,
      planDetailsError: null
    }
  case GET_PLANS_SUCCESS:
    return {
      ...state,
      plansRequesting: false,
      plans: action.data,
      totalPages: action.totalPages,
      plansError: null
    }
  case GET_PLANS_ERROR:
    return {
      ...state,
      plansRequesting: false,
      plansError: action.error,
      plans: [],
      totalPages: 1
    }
  case GET_PLAN_DETAILS_REQUEST:
    return {
      ...state,
      planDetailsRequesting: true,
      planDetailsData: {},
      planDetailsSuccess: false,
      planDetailsError: null
    }
  case GET_PLAN_DETAILS_SUCCESS:
    return {
      ...state,
      planDetailsRequesting: false,
      planDetailsData: action.data,
      planDetailsSuccess: true,
      planDetailsError: null
    }
  case GET_PLAN_DETAILS_ERROR:
    return {
      ...state,
      planDetailsRequesting: false,
      planDetailsError: action.error,
      planDetailsData: {},
      planDetailsSuccess: false
    }
  case GET_SELLERS_BY_PLAN_INFINTY_REQUEST:
    return {
      ...state,
      sellersPlanInfinityRequesting: true
    }
  case GET_SELLERS_BY_PLAN_REQUEST:
    return {
      ...state,
      sellersPlanRequesting: true,
      sellersPlan: [],
      totalPagesSellers: 1,
      totalSellers: 0,
      sellersPlanError: null
    }
  case GET_SELLERS_BY_PLAN_SUCCESS:
    return {
      ...state,
      sellersPlanRequesting: false,
      sellersPlan: action.data,
      totalPagesSellers: action.totalPages,
      totalSellers: action.totalItems,
      sellersPlanError: null
    }
  case GET_SELLERS_BY_PLAN_INFINTY_SUCCESS:
    return {
      ...state,
      sellersPlanInfinityRequesting: false,
      sellersPlanRequesting: false,
      sellersPlan: [...state.sellersPlan, ...action.data],
      totalPagesSellers: action.totalPages,
      totalSellers: action.totalItems,
      sellersPlanError: null
    }
  case GET_SELLERS_BY_PLAN_ERROR:
  case GET_SELLERS_BY_PLAN_INFINTY_ERROR:
    return {
      ...state,
      sellersPlanRequesting: false,
      sellersPlan: [],
      totalPagesSellers: 1,
      totalSellers: 0,
      sellersPlanError: action.error
    }
  case SET_SELLER_PLAN_REQUEST:
    return {
      ...state,
      setSellerPlanRequesting: true,
      setSellerPlanSuccess: false,
      setSellerPlanError: null
    }
  case SET_SELLER_PLAN_SUCCESS:
    return {
      ...state,
      setSellerPlanRequesting: false,
      setSellerPlanSuccess: true,
      setSellerPlanError: null
    }
  case SET_SELLER_PLAN_ERROR:
    return {
      ...state,
      setSellerPlanRequesting: false,
      setSellerPlanSuccess: true,
      setSellerPlanError: action.error
    }
  case GET_SELLER_ACTIVE_PLAN_REQUEST:
    return {
      ...state,
      sellerActivePlanRequesting: true,
      sellerActivePlanSuccess: false,
      sellerActivePlan: null,
      sellerActivePlanError: null
    }
  case GET_SELLER_ACTIVE_PLAN_SUCCESS:
    return {
      ...state,
      sellerActivePlanRequesting: false,
      sellerActivePlanSuccess: true,
      sellerActivePlan: action.activePlan,
      sellerActivePlanError: null
    }
  case GET_SELLER_ACTIVE_PLAN_ERROR:
    return {
      ...state,
      sellerActivePlanRequesting: false,
      sellerActivePlanSuccess: false,
      sellerActivePlan: null,
      sellerActivePlanError: action.error
    }
  case SET_SELLER_PLAN_RESET:
    return {
      ...state,
      setSellerPlanSuccess: null
    }
  case RESET_SELLERS_PLAN:
    return {
      sellersPlan: []
    }
  case RESET_PLAN_DETAILS:
    return {
      ...state,
      planDetailsRequesting: true,
      planDetailsData: {},
      planDetailsSuccess: false,
      planDetailsError: null,
      sellersPlanRequesting: true,
      sellersPlan: [],
      totalPagesSellers: 1,
      totalSellers: 0,
      sellersPlanError: null
    }
  case RESET_PLANS: {
    return INITIAL_STATE
  }
  default:
    return state
  }
}
