import {
  INVALIDATE_CONNECTED_ACCOUNT_TOKEN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../Constants/ActionTypes'
import { removeAccount } from 'src/Utils/Utils'

export const INITIAL_STATE = {
  connectedAccounts: []
}

export default function access (state = INITIAL_STATE, action) {
  switch (action.type) {
  case LOGIN_SUCCESS:
    let connectedAccounts = state.connectedAccounts.map(account => {
      if (account.userId === action.userId &&
        account.marketplaceId === action.marketplaceId &&
        account.disconnected) {
        account = {
          ...action,
          disconnected: false,
          type: undefined
        }
      }

      return account
    })

    const userNotConnected = !connectedAccounts.some(account => {
      return account.userId === action.userId && action.marketplaceId === account.marketplaceId
    })

    if (userNotConnected) {
      const newConnectedUser = {
        ...action,
        disconnected: false,
        type: undefined
      }

      connectedAccounts.push(newConnectedUser)
    }

    return {
      ...state,
      connectedAccounts
    }
  case LOGOUT_SUCCESS:
  case INVALIDATE_CONNECTED_ACCOUNT_TOKEN:
    return {
      ...state,
      connectedAccounts: removeAccount(action, state.connectedAccounts, true)
    }
  default:
    return state
  }
}
