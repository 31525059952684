import {
  GET_BANK_ACCOUNTS_REQUEST,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_ERROR,
  GET_ACTIVE_BANK_ACCOUNT_REQUEST,
  GET_ACTIVE_BANK_ACCOUNT_SUCCESS,
  GET_ACTIVE_BANK_ACCOUNT_ERROR,
  LOGOUT_SUCCESS,
  ACTIVATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_RECEIVING_POLICY_REQUEST,
  UPDATE_RECEIVING_POLICY_SUCCESS,
  UPDATE_RECEIVING_POLICY_ERROR,
  ASSOCIATE_BANK_ACCOUNT_REQUEST,
  ASSOCIATE_BANK_ACCOUNT_SUCCESS,
  ASSOCIATE_BANK_ACCOUNT_ERROR,
  RESET_BANK_ACCOUNTS,
  GET_DEFAULT_BANK_RECEIVER_SUCCESS,
  GET_DEFAULT_BANK_RECEIVER_REQUEST,
  GET_DEFAULT_BANK_RECEIVER_ERROR,
  GET_RECEIVING_POLICY_REQUEST,
  GET_RECEIVING_POLICY_SUCCESS,
  GET_RECEIVING_POLICY_ERROR,
  UPDATE_DEFAULT_CREDIT_REQUEST,
  UPDATE_DEFAULT_CREDIT_SUCCESS,
  UPDATE_DEFAULT_CREDIT_ERROR,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_ERROR,
  CREATE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_REQUEST,
  RESET_RECIPIENT_MEANS
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  bankAccounts: [],
  isBankAccountsRequesting: false,
  getBankAccountsError: null,
  isDeleteBankAccountRequesting: false,
  activeBankAccount: null,

  activeRecipient: null,
  isActiveRecipientRequesting: false,
  activeRecipientError: null,

  activateMeanRequesting: false,
  activateMeanSuccess: null,
  activateMeanError: null,

  createBankAccountError: null,
  createBankAccountRequesting: false,
  createBankAccountSuccess: false,

  associateBankAccountError: null,
  associateBankAccountRequesting: false,
  associateBankAccountSuccess: false,

  updateReceivingPolicyRequest: false,
  updateReceivingPolicySuccess: null,
  updateReceivingPolicyError: null,

  getReceivingPolicyRequesting: false,
  getReceivingPolicySuccess: null,
  getReceivingPolicyError: null,

  // Sidebar & Form control variables
  createBankAccountFormValues: null,
  openCreateBankAccountForm: false,

  bankAccountManagerType: '',
  openBankAccountManager: false,
  selectedBankAccount: null,

  receivingPolicy: null,

  bankAccountReceiverRequesting: false,
  bankAccountReceiver: null,
  bankAccountReceiverError: null,
  updateDefaultCreditRequest: false,
  updateDefaultCreditError: null,
  updateDefaultCreditSuccess: null
}

export default function bankAccounts (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_BANK_ACCOUNTS_REQUEST:
    return {
      ...state,
      isBankAccountsRequesting: true,
      getBankAccountsError: null,
      bankAccounts: [],
      activeBankAccount: null,
      bankAccountsNumberOfPages: 1
    }
  case GET_BANK_ACCOUNTS_SUCCESS:
    return {
      ...state,
      isBankAccountsRequesting: false,
      getBankAccountsError: null,
      bankAccounts: action.bankAccounts
    }
  case GET_BANK_ACCOUNTS_ERROR:
    return {
      ...state,
      isBankAccountsRequesting: false,
      getBankAccountsError: action.error,
      bankAccounts: [],
      activeBankAccount: null,
      bankAccountsNumberOfPages: 1
    }
  case GET_ACTIVE_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      activeRecipient: null,
      isActiveRecipientRequesting: true,
      activeRecipientError: null
    }
  case GET_ACTIVE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      activeRecipient: action.activeRecipient,
      isActiveRecipientRequesting: false,
      activeRecipientError: null
    }
  case GET_ACTIVE_BANK_ACCOUNT_ERROR:
    return {
      ...state,
      activeRecipient: null,
      isActiveRecipientRequesting: false,
      activeRecipientError: action.error
    }
  case ACTIVATE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      activateMeanSuccess: true,
      bankAccounts: action.bankAccounts,
      activeBankAccount: action.activeBankAccount,
      isActivateRecipientRequesting: false,
      openBankAccountManager: false,
      bankAccountCreated: null
    }
  case GET_DEFAULT_BANK_RECEIVER_REQUEST:
    return {
      ...state,
      bankAccountReceiverRequesting: true,
      bankAccountReceiver: null,
      bankAccountReceiverError: null
    }
  case GET_DEFAULT_BANK_RECEIVER_SUCCESS:
    return {
      ...state,
      bankAccountReceiverRequesting: false,
      bankAccountReceiver: action.bankAccountReceiver,
      bankAccountReceiverError: null
    }
  case GET_DEFAULT_BANK_RECEIVER_ERROR:
    return {
      ...state,
      bankAccountReceiverRequesting: false,
      bankAccountReceiver: null,
      bankAccountReceiverError: action.error
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  case UPDATE_RECEIVING_POLICY_REQUEST:
    return {
      ...state,
      updateReceivingPolicyRequest: true,
      updateReceivingPolicySuccess: null,
      updateReceivingPolicyError: null
    }
  case UPDATE_RECEIVING_POLICY_SUCCESS:
    return {
      ...state,
      updateReceivingPolicyRequest: false,
      updateReceivingPolicySuccess: action.receivingPolicy,
      receivingPolicy: action.receivingPolicy,
      updateReceivingPolicyError: null
    }
  case UPDATE_RECEIVING_POLICY_ERROR:
    return {
      ...state,
      updateReceivingPolicyRequest: false,
      updateReceivingPolicySuccess: null,
      updateReceivingPolicyError: action.error
    }
  case GET_RECEIVING_POLICY_REQUEST:
    return {
      ...state,
      getReceivingPolicyRequesting: true,
      receivingPolicy: null,
      getReceivingPolicyError: null,
      getReceivingPolicySuccess: null
    }
  case GET_RECEIVING_POLICY_SUCCESS:
    return {
      ...state,
      getReceivingPolicyRequesting: false,
      receivingPolicy: action.data,
      getReceivingPolicyError: null,
      getReceivingPolicySuccess: true
    }
  case GET_RECEIVING_POLICY_ERROR:
    return {
      ...state,
      getReceivingPolicyRequesting: false,
      receivingPolicy: null,
      getReceivingPolicyError: action.error,
      getReceivingPolicySuccess: null
    }
  case ASSOCIATE_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      associateBankAccountError: null,
      associateBankAccountRequesting: true,
      associateBankAccountSuccess: false
    }
  case ASSOCIATE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      associateBankAccountError: null,
      associateBankAccountRequesting: false,
      associateBankAccountSuccess: true
    }
  case ASSOCIATE_BANK_ACCOUNT_ERROR:
    return {
      ...state,
      associateBankAccountError: action.error,
      associateBankAccountRequesting: false,
      associateBankAccountSuccess: false
    }
  case RESET_BANK_ACCOUNTS:
    return {
      ...state,
      createBankAccountError: null,
      createBankAccountRequesting: false,
      createBankAccountSuccess: false,
      updateReceivingPolicyRequest: false,
      updateReceivingPolicySuccess: null,
      updateReceivingPolicyError: null,
      associateBankAccountError: null,
      associateBankAccountRequesting: false,
      associateBankAccountSuccess: false
    }
  case UPDATE_DEFAULT_CREDIT_REQUEST:
    return {
      ...state,
      updateDefaultCreditRequest: true,
      updateDefaultCreditError: null,
      updateDefaultCreditSuccess: null
    }
  case UPDATE_DEFAULT_CREDIT_SUCCESS:
    return {
      ...state,
      updateDefaultCreditRequest: false,
      updateDefaultCreditError: null,
      updateDefaultCreditSuccess: true,
      receivingPolicy: {
        ...state.receivingPolicy,
        transfer_enabled: true
      },
      bankAccountReceiver: action.bankAccountId
    }
  case UPDATE_DEFAULT_CREDIT_ERROR:
    return {
      ...state,
      updateDefaultCreditRequest: false,
      updateDefaultCreditError: action.error,
      updateDefaultCreditSuccess: null
    }
  case CREATE_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      createBankAccountSuccess: false,
      createBankAccountRequesting: true,
      createBankAccountError: null
    }
  case CREATE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      bankAccounts: action.bankAccounts,
      bankAccountReceiver: action.bankReceiver || state.bankAccountReceiver,
      receivingPolicy: {
        ...state.receivingPolicy,
        transfer_enabled: action.bankReceiver ? true : state.receivingPolicy.transfer_enabled
      },
      createBankAccountSuccess: true,
      createBankAccountRequesting: false,
      createBankAccountError: null
    }
  case CREATE_BANK_ACCOUNT_ERROR:
    return {
      ...state,
      createBankAccountSuccess: false,
      createBankAccountRequesting: false,
      createBankAccountError: action.error
    }
  case DELETE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      bankAccounts: action.bankAccounts,
      isDeleteBankAccountRequesting: false
    }
  case DELETE_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      isDeleteBankAccountRequesting: true
    }
  case RESET_RECIPIENT_MEANS:
    return {
      ...state,
      bankAccounts: []
    }
  default:
    return state
  }
}
