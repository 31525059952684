import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, Switch } from 'react-router-dom'
import LoginContainer from 'src/Containers/Authentication/LoginContainer'
import ForgotPassContainer from 'src/Containers/Authentication/ForgotPassContainer'
import NotFoundPageComponent from 'src/Components/NotFoundPage/NotFoundPageComponent'
import CreateUserContainer from 'src/Containers/Authentication/CreateUserContainer'
import AccessContainer from 'src/Containers/Authentication/AccessContainer'
import TokenExpiredContainer from 'src/Containers/TokenExpired/TokenExpiredContainer'
import RecoverPasswordContainer from 'src/Containers/Authentication/RecoverPasswordContainer'
// const LazyLogin = React.lazy(
//   () => import('src/Containers/Authentication/LoginContainer')
// )

const MainRoutes = ({ isAccountCreationDisabled }) => {
  return (
    <Switch>
      <Route exact path='/not-context' component={NotFoundPageComponent} />
      <Route exact path='/login' component={LoginContainer} />
      <Route
        exact
        path='/esqueci-minha-senha'
        component={ForgotPassContainer}
      />
      <Route
        exact
        path='/recuperar-senha'
        component={RecoverPasswordContainer}
      />
      <Route exact path='/access' component={AccessContainer} />
      {!isAccountCreationDisabled && (
        <Route exact path='/criar-conta' component={CreateUserContainer} />
      )}
      <Route exact path='/token-invalido' component={TokenExpiredContainer} />
      <Redirect
        to={`/login?redirect=${window.location.href.split(location.host)[1]}`}
      />
    </Switch>
  )
}

MainRoutes.propTypes = {
  isAccountCreationDisabled: PropTypes.bool
}

export { MainRoutes }
