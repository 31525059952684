import {
  RESET_BALANCES,
  GET_BALANCES_REQUEST,
  GET_BALANCES_FILTERS,
  GET_BALANCES_SUCCESS,
  GET_BALANCES_ERROR,
  GET_CURRENT_BALANCE_REQUEST,
  GET_CURRENT_BALANCE_SUCCESS,
  GET_CURRENT_BALANCE_ERROR,
  LOGOUT_SUCCESS,
  GET_BLOCKED_BALANCE_REQUEST,
  GET_BLOCKED_BALANCE_SUCCESS,
  GET_BLOCKED_BALANCE_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  balancesRequesting: false,
  balancesSuccess: false,
  balancesError: null,
  balancesItems: null,
  balancesPageSize: 100,
  currentBalanceRequesting: false,
  currentBalanceSuccess: false,
  currentBalanceError: null,
  currentBalance: null,
  blockedBalanceRequesting: false,
  blockedBalanceSuccess: false,
  blockedBalanceError: null,
  blockedBalance: null,
  balancesFilters: null
}

export default function balances (state = INITIAL_STATE, action) {
  switch (action.type) {
  case RESET_BALANCES:
    return {
      ...state,
      balancesItems: null
    }
  case GET_BALANCES_FILTERS:
    return {
      ...state,
      balancesFilters: action.balancesFilters
    }
  case GET_BALANCES_REQUEST:
    return {
      ...state,
      balancesRequesting: true,
      balancesSuccess: false,
      balancesError: null
    }
  case GET_BALANCES_SUCCESS:
    return {
      ...state,
      balancesRequesting: false,
      balancesSuccess: true,
      balancesError: null,
      balancesTotalPages: action.totalPages,
      balancesTotal: action.total,
      balancesPageSize: action.pageSize,
      balancesItems: action.balances
    }
  case GET_BALANCES_ERROR:
    return {
      ...INITIAL_STATE,
      balancesRequesting: false,
      balancesSuccess: false,
      balancesTotalPages: 1,
      balancesTotal: 0,
      balancesItems: null,
      balancesError: action.error
    }
  case GET_CURRENT_BALANCE_REQUEST:
    return {
      ...state,
      currentBalanceRequesting: true,
      currentBalanceSuccess: false,
      currentBalanceError: null,
      currentBalance: null
    }
  case GET_CURRENT_BALANCE_SUCCESS:
    return {
      ...state,
      currentBalanceRequesting: false,
      currentBalanceSuccess: true,
      currentBalanceError: null,
      currentBalance: action.currentBalance
    }
  case GET_CURRENT_BALANCE_ERROR:
    return {
      ...state,
      currentBalanceRequesting: false,
      currentBalanceSuccess: false,
      currentBalanceError: action.currentBalanceError,
      currentBalance: null
    }
  case GET_BLOCKED_BALANCE_REQUEST:
    return {
      ...state,
      blockedBalanceRequesting: true,
      blockedBalanceSuccess: false,
      blockedBalanceError: null,
      blockedBalance: null
    }
  case GET_BLOCKED_BALANCE_SUCCESS:
    return {
      ...state,
      blockedBalanceRequesting: false,
      blockedBalanceSuccess: true,
      blockedBalanceError: null,
      blockedBalance: action.blockedBalance
    }
  case GET_BLOCKED_BALANCE_ERROR:
    return {
      ...state,
      blockedBalanceRequesting: false,
      blockedBalanceSuccess: false,
      blockedBalanceError: action.blockedBalanceError,
      blockedBalance: null
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
