import {
  DELETE_BANK_ACCOUNT_ERROR,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  CREATE_SELLER_BANK_ACCOUNT_REQUEST,
  CREATE_SELLER_BANK_ACCOUNT_SUCCESS,
  CREATE_SELLER_BANK_ACCOUNT_ERROR
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  bankAccounts: [],
  isDeleteBankAccountRequesting: false,

  createBankAccountError: null,
  createBankAccountRequesting: false,
  createBankAccountSuccess: false,

  associateBankAccountError: null,
  associateBankAccountRequesting: false,
  associateBankAccountSuccess: false,

  // Sidebar & Form control variables
  createBankAccountFormValues: null,
  openCreateBankAccountForm: false,

  bankAccountManagerType: '',
  openBankAccountManager: false,
  selectedBankAccount: null
}

export default function bankAccount (state = INITIAL_STATE, action) {
  switch (action.type) {
  case CREATE_SELLER_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      createBankAccountRequesting: true,
      createBankAccountSuccess: false,
      createBankAccountError: null
    }
  case CREATE_SELLER_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      createBankAccountRequesting: false,
      createBankAccountSuccess: true,
      createBankAccountError: null,
      bankAccounts: action.bankAccounts,
      createBankAccountFormValues: null,
      openCreateBankAccountForm: false,
      openBankAccountManager: true,
      bankAccountManagerType: 'activateAfterCreate'
    }
  case CREATE_SELLER_BANK_ACCOUNT_ERROR:
    return {
      ...state,
      createBankAccountError: action.error,
      createBankAccountRequesting: false,
      createBankAccountSuccess: false
    }
  case DELETE_BANK_ACCOUNT_REQUEST:
    return {
      ...state,
      isDeleteBankAccountRequesting: true,
      deleteBankAccountError: null
    }
  case DELETE_BANK_ACCOUNT_SUCCESS:
    return {
      ...state,
      isDeleteBankAccountRequesting: false,
      deleteBankAccountError: null,
      bankAccounts: action.bankAccounts,
      openBankAccountManager: false
    }
  case DELETE_BANK_ACCOUNT_ERROR:
    return {
      ...state,
      isDeleteBankAccountRequesting: false,
      deleteBankAccountError: action.error
    }
  default:
    return state
  }
}
