import {
  GET_CUSTOM_REQUEST,
  GET_CUSTOM_SUCCESS,
  GET_CUSTOM_ERROR,
  UPDATE_CUSTOM_COLOR_REQUEST,
  UPDATE_CUSTOM_COLOR_SUCCESS,
  UPDATE_CUSTOM_COLOR_ERROR,
  UPDATE_CUSTOM_COLORED_LOGO_REQUEST,
  UPDATE_CUSTOM_COLORED_LOGO_SUCCESS,
  UPDATE_CUSTOM_COLORED_LOGO_ERROR,
  UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST,
  UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS,
  UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR,
  UPDATE_CUSTOM_SLUG_REQUEST,
  UPDATE_CUSTOM_SLUG_SUCCESS,
  UPDATE_CUSTOM_SLUG_ERROR,
  UPDATE_SUPPORT_DATA_REQUEST,
  UPDATE_SUPPORT_DATA_SUCCESS,
  UPDATE_SUPPORT_DATA_ERROR,
  UPDATE_TEMPORARY_COLOR,
  RESET_TEMPORARY_COLOR,
  UPDATE_TEMPORARY_COLORED_LOGO,
  RESET_TEMPORARY_COLORED_LOGO,
  UPDATE_TEMPORARY_MONOCHROME_LOGO,
  RESET_TEMPORARY_MONOCHROME_LOGO,
  RESET_CUSTOM_ERRORS,
  GET_SLUG_REQUEST,
  GET_SLUG_SUCCESS,
  GET_SLUG_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  color: '#f19512',
  temporaryColor: null,
  logo: '/assets/imgs/logo-zoop.png',
  temporaryColoredLogo: null,
  monochromeLogo: null,
  temporaryMonochromeLogo: null,
  supportPhone: null,
  supportEmail: null,
  favicon: null,
  isRequesting: false,
  error: false,
  updateCustomSlugRequesting: false,
  updateCustomSlugError: null,
  updateCustomSlugSuccess: null,
  updateSupportDataRequesting: false,
  updateSupportDataError: null,
  updateSupportDataSuccess: null,

  updateColorRequesting: false,
  updateColorError: null,
  updateColorSuccess: false,

  updateCustomColoredLogoRequesting: false,
  updateCustomColoredLogoSuccess: false,
  updateCustomColoredLogoError: null,

  updateCustomMonochromeLogoRequesting: false,
  updateCustomMonochromeLogoSuccess: false,
  updateCustomMonochromeLogoError: null,
  slug: null,
  getSlugRequest: false,
  getSlugSuccess: false,
  getSlugError: null
}

export default function custom(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOM_REQUEST:
      return {
        ...state,
        isRequesting: true,
        error: null
      }
    case GET_CUSTOM_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: null,
        logo: action.coloredLogo,
        monochromeLogo: action.monochromeLogo,
        custom: action.custom,
        color: action.color,
        supportEmail: action.supportEmail,
        supportPhone: action.supportPhone
      }
    case GET_CUSTOM_ERROR:
      return {
        ...INITIAL_STATE,
        isRequesting: false,
        error: action.error
      }
    case UPDATE_TEMPORARY_COLOR:
      return {
        ...state,
        temporaryColor: action.color
      }
    case RESET_TEMPORARY_COLOR:
      return {
        ...state,
        temporaryColor: null
      }
    case UPDATE_CUSTOM_COLOR_REQUEST:
      return {
        ...state,
        updateColorRequesting: true,
        updateColorSuccess: false,
        updateColorError: null
      }
    case UPDATE_CUSTOM_COLOR_SUCCESS:
      return {
        ...state,
        updateColorRequesting: false,
        color: action.color,
        updateColorSuccess: true,
        updateColorError: null,
        temporaryColor: null
      }
    case UPDATE_CUSTOM_COLOR_ERROR:
      return {
        ...state,
        updateColorRequesting: false,
        updateColorSuccess: false,
        updateColorError: action.error
      }
    case UPDATE_TEMPORARY_COLORED_LOGO:
      return {
        ...state,
        temporaryColoredLogo: action.logo
      }
    case RESET_TEMPORARY_COLORED_LOGO:
      return {
        ...state,
        temporaryColoredLogo: null
      }
    case UPDATE_CUSTOM_COLORED_LOGO_REQUEST:
      return {
        ...state,
        updateCustomColoredLogoRequesting: true,
        updateCustomColoredLogoSuccess: false,
        updateCustomColoredLogoError: null
      }
    case UPDATE_CUSTOM_COLORED_LOGO_SUCCESS:
      return {
        ...state,
        updateCustomColoredLogoRequesting: false,
        updateCustomColoredLogoSuccess: true,
        logo: action.logoSource,
        updateCustomColoredLogoError: null,
        temporaryColoredLogo: null
      }
    case UPDATE_CUSTOM_COLORED_LOGO_ERROR:
      return {
        ...state,
        updateCustomColoredLogoRequesting: false,
        updateCustomColoredLogoSuccess: false,
        updateCustomColoredLogoError: action.error
      }
    case UPDATE_TEMPORARY_MONOCHROME_LOGO:
      return {
        ...state,
        temporaryMonochromeLogo: action.logo
      }
    case RESET_TEMPORARY_MONOCHROME_LOGO:
      return {
        ...state,
        temporaryMonochromeLogo: null
      }
    case UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST:
      return {
        ...state,
        updateCustomMonochromeLogoRequesting: true,
        updateCustomMonochromeLogoSuccess: false,
        updateCustomMonochromeLogoError: null
      }
    case UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS:
      return {
        ...state,
        updateCustomMonochromeLogoRequesting: false,
        updateCustomMonochromeLogoSuccess: true,
        monochromeLogo: action.logoSource,
        updateCustomMonochromeLogoError: null,
        temporaryMonochromeLogo: null
      }
    case UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR:
      return {
        ...state,
        updateCustomMonochromeLogoRequesting: false,
        updateCustomMonochromeLogoSuccess: false,
        updateCustomMonochromeLogoError: action.error
      }
    case UPDATE_CUSTOM_SLUG_REQUEST:
      return {
        ...state,
        updateCustomSlugRequesting: true,
        updateCustomSlugError: null,
        updateCustomSlugSuccess: null
      }
    case UPDATE_CUSTOM_SLUG_SUCCESS:
      return {
        ...state,
        updateCustomSlugRequesting: false,
        updateCustomSlugError: false,
        updateCustomSlugSuccess: true
      }
    case UPDATE_CUSTOM_SLUG_ERROR:
      return {
        ...state,
        updateCustomSlugRequesting: false,
        updateCustomSlugError: action.error,
        updateCustomSlugSuccess: false
      }
    case UPDATE_SUPPORT_DATA_REQUEST:
      return {
        ...state,
        updateSupportDataRequesting: true,
        updateSupportDataError: null,
        updateSupportDataSuccess: null
      }
    case UPDATE_SUPPORT_DATA_SUCCESS:
      return {
        ...state,
        updateSupportDataRequesting: false,
        updateSupportDataError: false,
        updateSupportDataSuccess: true,
        supportEmail: action.data.supportEmail,
        supportPhone: action.data.supportPhone
      }
    case UPDATE_SUPPORT_DATA_ERROR:
      return {
        ...state,
        updateSupportDataRequesting: false,
        updateSupportDataError: action.error,
        updateSupportDataSuccess: false
      }
    case RESET_CUSTOM_ERRORS:
      return {
        ...state,
        [action.errorType]: null
      }
    case GET_SLUG_REQUEST:
      return {
        ...state,
        getSlugRequest: true,
        getSlugSuccess: false,
        getSlugError: null
      }
    case GET_SLUG_SUCCESS:
      return {
        ...state,
        slug: action.slug,
        getSlugRequest: false,
        getSlugSuccess: true,
        getSlugError: null
      }
    case GET_SLUG_ERROR:
      return {
        ...state,
        getSlugRequest: false,
        getSlugSuccess: false,
        getSlugError: action.error
      }

    default:
      return state
  }
}
