import React from 'react'
import PropTypes from 'prop-types'
import { toast, Slide } from 'react-toastify'
import MainTemplate, {
  Header,
  Container,
  Column,
  Footer
} from 'src/UIComponents/Template'
import UIFooter from 'src/UIComponents/Footer/Footer'

toast.configure({
  style: { width: '338px' },
  transition: Slide
})

const MainPage = ({ history, children, backofficeDisclaimerMargin }) => {
  return (
    <MainTemplate noPadding>
      <Header history={history} />
      <Container
        headerMargin
        backofficeDisclaimerMargin={backofficeDisclaimerMargin}
      >
        <Column>{children}</Column>
      </Container>
      <Footer>
        <UIFooter />
      </Footer>
    </MainTemplate>
  )
}

MainPage.propTypes = {
  history: PropTypes.object,
  children: PropTypes.any,
  backofficeDisclaimerMargin: PropTypes.bool
}

export default MainPage
