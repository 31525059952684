import {
  HINT_VIEW_CHECK_REQUEST,
  HINT_VIEW_CHECK_SUCCESS,
  HINT_VIEW_CHECK_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  viewedHintsRequesting: false,
  viewedHintsError: null,
  viewedHints: null
}

export default function onboarding (state = INITIAL_STATE, action) {
  switch (action.type) {
  case HINT_VIEW_CHECK_REQUEST:
    return {
      ...state,
      viewedHintsRequesting: true,
      viewedHintsError: null,
      viewedHints: null
    }
  case HINT_VIEW_CHECK_SUCCESS:
    return {
      ...state,
      viewedHintsRequesting: false,
      viewedHintsError: null,
      viewedHints: action.hints
    }
  case HINT_VIEW_CHECK_ERROR:
    return {
      ...state,
      viewedHintsRequesting: false,
      viewedHintsError: action.e,
      viewedHints: null
    }
  default:
    return state
  }
}
