import {
  LIST_TRANSFERS_REQUEST,
  LIST_TRANSFERS_SUCCESS,
  LIST_TRANSFERS_ERROR,
  LOGOUT_SUCCESS,
  GET_TRANSFER_BY_ID_REQUEST,
  GET_TRANSFER_BY_ID_SUCCESS,
  GET_TRANSFER_BY_ID_ERROR,
  GET_TRANSFER_SENDER_REQUEST,
  GET_TRANSFER_SENDER_SUCCESS,
  GET_TRANSFER_SENDER_ERROR,
  GET_TRANSFER_RECIPIENT_REQUEST,
  GET_TRANSFER_RECIPIENT_SUCCESS,
  GET_TRANSFER_RECIPIENT_ERROR,
  GET_TRANSFER_HISTORY_REQUEST,
  GET_TRANSFER_HISTORY_SUCCESS,
  GET_TRANSFER_HISTORY_ERROR,
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_ERROR,
  RESET_CREATE_TRANSFER
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  isTransfersRequesting: false,
  transfersSuccess: false,
  transfersError: null,

  transfers: [],
  transfersTotalPages: 1,
  transfersPageSize: 100,
  futureItems: [],
  isFutureTransfersRequesting: false,
  futureTransfersSuccess: false,
  futureTransfersError: null,
  calendarTransfersData: [],
  transfersTotalItems: 0,

  transfersData: [],
  boxTitle: 'Pagamentos do dia',
  boxSubTitle: '',

  boxRequesting: false,

  transferDetail: null,
  transferDetailRequesting: false,
  transferDetailError: null,

  transferSender: null,
  transferSenderDetailRequesting: false,
  transferSenderDetailError: null,

  transferRecipient: null,
  transferRecipientDetailRequesting: false,

  transferRecipientDetailError: null,

  transferHistory: [],
  transferQueryCount: 0,
  transferHistoryHasMore: false,
  transferHistoryPage: 0,
  transferHistoryRequesting: false,
  transferHistoryError: false,

  createTransferRequesting: false,
  createTransferSuccess: null,
  createTransferError: null
}

export default function transfers (state = INITIAL_STATE, action) {
  switch (action.type) {
  case LIST_TRANSFERS_REQUEST:
    return {
      ...state,
      transfersRequesting: true,
      transfersError: null
    }
  case LIST_TRANSFERS_SUCCESS:
    return {
      ...state,
      transfersRequesting: false,
      transfersError: null,
      transfers: action.transfers,
      transfersTotalPages: action.totalPages,
      transfersTotalItems: action.totalItems,
      transfersPageSize: action.limit
    }
  case LIST_TRANSFERS_ERROR:
    return {
      ...state,
      transfersRequesting: false,
      transfersError: action.error,
      transfersTotalItems: 0,
      transfers: [],
      transfersTotalPages: 1
    }
  case GET_TRANSFER_BY_ID_REQUEST:
    return {
      ...state,
      transferDetailRequesting: true,
      transferDetailError: null,
      transferDetail: null
    }
  case GET_TRANSFER_BY_ID_SUCCESS:
    return {
      ...state,
      transferDetailRequesting: false,
      transferDetailError: null,
      transferDetail: action.transfer
    }
  case GET_TRANSFER_BY_ID_ERROR:
    return {
      ...state,
      transferDetailRequesting: false,
      transferDetailError: action.error,
      transferDetail: null
    }
  case GET_TRANSFER_HISTORY_REQUEST:
    return {
      ...state,
      transferHistoryRequesting: true,
      transferHistory: action.history,
      transferHistoryError: null
    }
  case GET_TRANSFER_HISTORY_SUCCESS:
    return {
      ...state,
      transferHistoryRequesting: false,
      transferHistory: [...state.transferHistory, ...action.history],
      transferQueryCount: action.queryCount,
      transferHistoryError: null
    }
  case GET_TRANSFER_HISTORY_ERROR:
    return {
      ...state,
      transferHistoryRequesting: false,
      transferHistory: [],
      transferQueryCount: 0,
      transferHistoryError: action.error
    }
  case GET_TRANSFER_SENDER_REQUEST:
    return {
      ...state,
      transferSenderDetailRequesting: true,
      transferSender: null,
      transferSenderDetailError: null
    }
  case GET_TRANSFER_SENDER_SUCCESS:
    return {
      ...state,
      transferSenderDetailRequesting: false,
      transferSender: action.sender,
      transferSenderDetailError: null
    }
  case GET_TRANSFER_SENDER_ERROR:
    return {
      ...state,
      transferSenderDetailRequesting: false,
      transferSender: null,
      transferSenderDetailError: action.error
    }
  case GET_TRANSFER_RECIPIENT_REQUEST:
    return {
      ...state,
      transferRecipientDetailRequesting: true,
      transferRecipient: null,
      transferRecipientDetailError: null
    }
  case GET_TRANSFER_RECIPIENT_SUCCESS:
    return {
      ...state,
      transferRecipientDetailRequesting: false,
      transferRecipient: action.recipient,
      transferRecipientDetailError: null
    }
  case GET_TRANSFER_RECIPIENT_ERROR:
    return {
      ...state,
      transferRecipientDetailRequesting: false,
      transferRecipient: null,
      transferRecipientDetailError: action.error
    }
  case CREATE_TRANSFER_REQUEST:
    return {
      ...state,
      createTransferRequesting: true,
      createTransferSuccess: null,
      createTransferError: null
    }
  case CREATE_TRANSFER_SUCCESS:
    return {
      ...state,
      createTransferRequesting: false,
      createTransferSuccess: action.transfer,
      createTransferError: null
    }
  case CREATE_TRANSFER_ERROR:
    return {
      ...state,
      createTransferRequesting: false,
      createTransferSuccess: null,
      createTransferError: action.error
    }

  case RESET_CREATE_TRANSFER:
    if (action.justClearError) {
      return {
        createTransferError: null
      }
    }

    return {
      ...state,
      createTransferRequesting: false,
      createTransferSuccess: null,
      createTransferError: null
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
