import {
  MERCHANT_CODES_REQUESTING,
  MERCHANT_CODES_SUCCESS,
  MERCHANT_CODES_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  mccRequesting: false,
  mccData: null,
  mccError: null
}

export default function merchantCategoryCodes (state = INITIAL_STATE, action) {
  switch (action.type) {
  case MERCHANT_CODES_REQUESTING:
    return {
      ...state,
      mccRequesting: true,
      mccData: null,
      mccError: null
    }
  case MERCHANT_CODES_SUCCESS:
    return {
      ...state,
      mccRequesting: false,
      mccData: action.data,
      mccError: null
    }
  case MERCHANT_CODES_ERROR:
    return {
      ...state,
      mccRequesting: false,
      mccData: null,
      mccError: action.error
    }
  default:
    return state
  }
}
