import {
  CHECK_TOKEN_REQUEST,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RECOVER_ACCESS_REQUEST,
  RECOVER_ACCESS_SUCCESS,
  RECOVER_ACCESS_ERROR,
  RESET_AUTH,
  RESET_AUTH_ERROR,
  CONFIRM_USER_REQUEST,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_ERROR,
  MFA_REQUEST,
  MFA_SUCCESS,
  MFA_ERROR,
  RESET_MFA_ERROR,
  CHECK_CREDENTIALS_ERROR,
  CHECK_CREDENTIALS_REQUEST,
  CHECK_CREDENTIALS_SUCCESS
} from '../Constants/ActionTypes'
import Cookies from 'universal-cookie'

export const INITIAL_STATE = {
  token: null,
  authError: null,
  authRequesting: null,
  isLoggedIn: null,
  checkCredentialsSuccess: null,
  checkCredentialsRequesting: null,
  checkCredentialsError: null,

  tokenValidateRequesting: false,
  tokenValidateSuccess: false,
  tokenValidateError: null,

  confirmUserRequesting: false,
  confirmUserError: null,
  confirmUserSuccess: false,
  confirmUserErrorMessage: null,

  accessOrigin: null,

  mfaRequesting: false,
  mfaSessionToken: null,
  mfaSessionIsValid: false,
  mfaError: null
}

export default function authentication(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_AUTH:
      return INITIAL_STATE
    case RESET_AUTH_ERROR:
      return {
        ...state,
        authError: null
      }
    case CHECK_CREDENTIALS_REQUEST:
      return {
        ...state,
        checkCredentialsRequesting: true,
        checkCredentialsError: false,
        checkCredentialsSuccess: false
      }
    case CHECK_CREDENTIALS_SUCCESS:
      return {
        ...state,
        checkCredentialsRequesting: false,
        checkCredentialsError: false,
        checkCredentialsSuccess: true
      }
    case CHECK_CREDENTIALS_ERROR:
      return {
        ...state,
        checkCredentialsRequesting: false,
        checkCredentialsError: action.error,
        checkCredentialsSuccess: false
      }
    case CHECK_TOKEN_REQUEST:
      return {
        ...state,
        tokenValidateRequesting: true,
        tokenValidateSuccess: false,
        tokenValidateError: null
      }
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        tokenValidateRequesting: false,
        tokenValidateSuccess: true,
        tokenValidateError: null
      }
    case CHECK_TOKEN_ERROR:
      return {
        ...state,
        tokenValidateRequesting: false,
        tokenValidateSuccess: false,
        tokenValidateError: action.error
      }
    case LOGIN_REQUEST:
      return { ...state, authError: null, authRequesting: true }
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        authError: null,
        authRequesting: false,
        isLoggedIn: true,
        accessOrigin: action.accessOrigin
      }
    case LOGIN_ERROR:
      return {
        ...state,
        user: {
          id: null,
          username: null,
          token: null
        },
        authError: action.error,
        authRequesting: false,
        isLoggedIn: false
      }
    case CONFIRM_USER_REQUEST:
      return {
        ...state,
        confirmUserRequesting: true
      }
    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        confirmUserRequesting: false,
        confirmUserSuccess: true,
        confirmUserError: null
      }
    case CONFIRM_USER_ERROR:
      return {
        ...state,
        confirmUserRequesting: false,
        confirmUserError: action.error,
        confirmUserSuccess: null,
        confirmUserErrorMessage: action.customMessage
      }
    case MFA_REQUEST:
      return {
        ...state,
        mfaRequesting: true,
        mfaSessionToken: null,
        mfaSessionIsValid: false,
        mfaError: null
      }
    case MFA_SUCCESS:
      return {
        ...state,
        mfaRequesting: false,
        mfaSessionToken: action.sessionToken,
        mfaSessionIsValid: true,
        mfaError: null
      }
    case MFA_ERROR:
      return {
        ...state,
        mfaRequesting: false,
        mfaSessionToken: null,
        mfaSessionIsValid: false,
        mfaError: action.error
      }
    case RESET_MFA_ERROR:
      return {
        ...state,
        mfaError: null
      }
    case LOGOUT_SUCCESS:
      const cookies = new Cookies()
      cookies.remove('complete_register_session_panel')
      return action.reason
        ? { ...INITIAL_STATE, authError: action.reason }
        : { ...INITIAL_STATE }
    case RECOVER_ACCESS_REQUEST:
      return { ...state, error: null, requesting: true }
    case RECOVER_ACCESS_SUCCESS:
      return { ...state, error: null, requesting: false, passwordReseted: true }
    case RECOVER_ACCESS_ERROR:
      return { ...state, error: action.error, requesting: false }
    default:
      return state
  }
}
