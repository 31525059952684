import {
  GET_COMMISSIONS_ERROR,
  GET_COMMISSIONS_REQUEST,
  GET_COMMISSIONS_SUCCESS,
  GET_LAST_COMMISSION_ERROR,
  GET_LAST_COMMISSION_REQUEST,
  GET_LAST_COMMISSION_SUCCESS
} from 'src/Constants/ActionTypes'

export const INITIAL_STATE = {
  lastCommissionRequesting: null,
  lastCommission: undefined,
  lastCommissionError: null,

  commissionsRequesting: null,
  commissions: [],
  commissionsError: null,
  hasMoreCommissions: null,
  commissionsPage: 1
}

export default function commissions (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_LAST_COMMISSION_REQUEST:
    return {
      ...state,
      lastCommissionRequesting: true
    }
  case GET_LAST_COMMISSION_SUCCESS:
    return {
      ...state,
      lastCommissionRequesting: false,
      lastCommission: action.lastCommission,
      lastCommissionError: null
    }
  case GET_LAST_COMMISSION_ERROR:
    return {
      ...state,
      lastCommissionRequesting: false,
      lastCommissionError: action.error,
      lastCommission: undefined
    }
  case GET_COMMISSIONS_REQUEST:
    return {
      ...state,
      commissionsRequesting: true
    }
  case GET_COMMISSIONS_SUCCESS:
    return {
      ...state,
      commissionsRequesting: false,
      commissions: action.commissions,
      hasMoreCommissions: action.hasMore,
      commissionsPage: action.page,
      commissionsError: null
    }
  case GET_COMMISSIONS_ERROR:
    return {
      ...state,
      commissionsRequesting: false,
      commissionsError: action.error,
      commissionsPage: 1
    }
  default:
    return state
  }
}
