import {
  MARKETPLACE_CONTEXT_CHANGE_ERROR,
  MARKETPLACE_CONTEXT_CHANGE_REQUEST,
  MARKETPLACE_CONTEXT_CHANGE_SUCCESS,
  SELLER_CONTEXT_CHANGE_REQUEST,
  SELLER_CONTEXT_CHANGE_SUCCESS,
  SELLER_CONTEXT_CHANGE_ERROR,
  RESET_SELLER_CONTEXT,
  RESET_CONTEXT
} from 'src/Constants/ActionTypes'
import { LOGOUT_SUCCESS, UPDATE_SELLER_DETAILS_SUCCESS } from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  sellerContextChangeRequesting: false,
  sellerContextChangeSuccess: false,
  sellerContextChangeError: false,
  seller: null,

  marketplaceContextChangeRequesting: false,
  marketplace: null,
  marketplaceContextChangeFailure: null
}

export default function context (state = INITIAL_STATE, action) {
  switch (action.type) {
  case SELLER_CONTEXT_CHANGE_REQUEST:
    return {
      ...state,
      sellerContextChangeRequesting: true,
      sellerContextChangeSuccess: false,
      sellerContextChangeError: false
    }
  case UPDATE_SELLER_DETAILS_SUCCESS:
  case SELLER_CONTEXT_CHANGE_SUCCESS:
    return {
      ...state,
      sellerContextChangeRequesting: false,
      sellerContextChangeSuccess: true,
      sellerContextChangeError: false,
      seller: action.seller
    }
  case SELLER_CONTEXT_CHANGE_ERROR:
    return {
      ...state,
      sellerContextChangeRequesting: false,
      sellerContextChangeSuccess: false,
      sellerContextChangeError: action.error
    }
  case RESET_SELLER_CONTEXT:
    return {
      ...state,
      sellerContextChangeRequesting: false,
      sellerContextChangeSuccess: false,
      sellerContextChangeError: false,
      seller: null
    }
  case MARKETPLACE_CONTEXT_CHANGE_REQUEST:
    return {
      ...state,
      marketplaceContextChangeRequesting: true,
      marketplaceContextChangeFailure: null
    }
  case MARKETPLACE_CONTEXT_CHANGE_SUCCESS:
    return {
      ...INITIAL_STATE,
      marketplaceContextChangeRequesting: false,
      marketplace: action.marketplace,
      marketplaceContextChangeFailure: null
    }
  case MARKETPLACE_CONTEXT_CHANGE_ERROR:
    return {
      ...state,
      marketplaceContextChangeRequesting: false,
      marketplaceContextChangeFailure: action.error
    }
  case RESET_CONTEXT:
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
