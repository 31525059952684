import * as ability from 'src/Configs/ability'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BalancesContainer from '../Containers/Balances/Balances'
import Can from 'src/Helpers/Permission/Can'
import { ExportNotification } from 'src/Components/Report'
import FutureTransfersContainer from 'src/Containers/FutureTransfers/FutureTransfers'
import HomeContainer from 'src/Containers/Home/Home'
import MainPage from 'src/Page/MainPage/MainPage'
import MarketplaceConfigurationContainer from 'src/Containers/Configuration/MarketplaceConfiguration'
import NotFoundPageComponent from 'src/Components/NotFoundPage/NotFoundPageComponent'
import PropTypes from 'prop-types'
import SaleContainer from 'src/Containers/Sale/Sale'
import SaleDetailComponent from 'src/Components/SaleDetail'
import SellerConfiguration from 'src/Containers/Configuration/SellerConfiguration'
import SellerContainer from 'src/Containers/Seller/Seller'
import TransferContainer from 'src/Containers/Transfer/Transfer'
import TransferDetailComponent from 'src/Components/TransferDetail'
import AnticipationContainer from 'src/Containers/Anticipation'
import { getMarketplaceDetails } from 'src/Actions/marketplace'
import { useGetPermissionProfile } from 'src/Hooks/useGetPermissionProfile'
import { MFA } from '../Components/MFA'
import BillingPaymentsDetail from '../Components/BillingPaymentsDetail'
import { getHintViewCheck } from '../Actions/onboarding'

const DashRoutes = ({ baseNameSelectedMarketplaceId }) => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState()
  const { getProfile } = useGetPermissionProfile()

  const {
    marketplace,
    user,
    accessOrigin,
    mfaSessionIsValid,
    isLoggedIn,
    enableAnticipation
  } = useSelector(({ marketplace, user, authentication, settings }) => ({
    marketplace,
    user,
    accessOrigin: authentication.accessOrigin,
    mfaSessionIsValid: authentication.mfaSessionIsValid,
    isLoggedIn: authentication.isLoggedIn,
    enableAnticipation: settings.items.prepaymentByApiEnabled
  }))

  useEffect(() => {
    if (user?.id) {
      dispatch(getHintViewCheck())
    }
  }, [user])

  useEffect(() => {
    if (marketplace.id && user.permissions) {
      const profileAccess = getProfile()
      if (profileAccess) {
        setProfile(profileAccess)
        ability.setPermissionByProfile(profileAccess)
      }
    }
  }, [marketplace.id, user.permissions])

  useEffect(() => {
    if (marketplace.requestDetailsError) {
      throw new Error(marketplace.requestDetailsError)
    } else if (marketplace.id && !marketplace.details && isLoggedIn) {
      dispatch(getMarketplaceDetails())
    }
  }, [marketplace])

  if (!marketplace.details || !profile) {
    return <></>
  }

  const isAccessFromBackoffice = accessOrigin === 'backoffice'

  return (
    <BrowserRouter basename={baseNameSelectedMarketplaceId}>
      {!isAccessFromBackoffice && <MFA />}
      {
        ((!isAccessFromBackoffice && mfaSessionIsValid) || isAccessFromBackoffice) &&
        <Switch>
          <Redirect exact from='/access' to='/' />
          <Route path='/not-found' component={NotFoundPageComponent} />
          <MainPage backofficeDisclaimerMargin={isAccessFromBackoffice}>
            <Switch>
              <Redirect from='/login' to='/' />
              <Redirect exact from={['/', '*/marketplace/*']} to={profile === 'full_access_configuration' ? '/estabelecimentos' : '/home'} />
              <Redirect exact from='/estabelecimento/:sellerId' to={profile === 'full_access_configuration' ? '/estabelecimento/:sellerId/configuracoes' : '/estabelecimento/:sellerId/visao-geral'} />
              {
                profile === 'full_access_configuration' &&
                <Redirect
                  exact
                  from={[
                    '*/home',
                    '*/vendas',
                    '*/transferencias'
                  ]}
                  to='/estabelecimentos'
                />
              }
              {profile !== 'full_access_configuration' &&
                <Route
                  path={['/estabelecimento/:sellerId/visao-geral', '/home']}
                  component={HomeContainer}
                />
              }
              {profile !== 'full_access_configuration' &&
                <Route
                  path={['/estabelecimento/:sellerId/extrato']}
                  component={BalancesContainer}
                />
              }
              {profile !== 'full_access_configuration' &&
                <Route
                  path={[
                    '/vendas',
                    '/vendas/detalhe/:id',
                    '/estabelecimento/:sellerId/vendas',
                    '/estabelecimento/:sellerId/vendas/detalhe/:id'
                  ]}
                  component={SaleContainer}
                />
              }
              {profile !== 'full_access_configuration' &&
                <Route
                  path={[
                    '/transferencias',
                    '/transferencias/detalhe/:id',
                    '/estabelecimento/:sellerId/transferencias',
                    '/estabelecimento/:sellerId/transferencias/detalhe/:id'
                  ]}
                  component={TransferContainer} />
              }
              <Route exact path={['/estabelecimentos']} component={SellerContainer} />
              <Route path={[
                '/lancamentos-futuros',
                '/estabelecimento/:sellerId/lancamentos-futuros'
              ]} component={FutureTransfersContainer} />

              <Route exact path={['/estabelecimentos', '/estabelecimentos/:id']} component={SellerContainer} />
              <Route path='/configuracoes' component={MarketplaceConfigurationContainer} />
              <Route
                path={[
                  '/estabelecimento/:sellerId/configuracoes'
                ]}
                component={SellerConfiguration}
              />
              {enableAnticipation && <Route path='/antecipacoes' component={AnticipationContainer} />}
              <Redirect to='/not-found' />
            </Switch>
            {profile !== 'full_access_configuration' && <>
              <TransferDetailComponent />
              <SaleDetailComponent />
              <BillingPaymentsDetail />
            </>}
            <Can do='create' on='ExportTransaction'>
              <ExportNotification />
            </Can>
          </MainPage>
        </Switch>
      }
    </BrowserRouter>
  )
}

DashRoutes.propTypes = {
  baseNameSelectedMarketplaceId: PropTypes.string
}

export { DashRoutes }
