import {
  GET_RECEIPT_REQUEST,
  GET_RECEIPT_SUCCESS,
  GET_RECEIPT_ERROR,
  SEND_RECEIPT_REQUEST,
  SEND_RECEIPT_SUCCESS,
  SEND_RECEIPT_ERROR,
  RESET_RECEIPT,
  RESET_SEND_RECEIPT
} from '../Constants/ActionTypes'

export const INITIAL_STATE = {
  sendReceiptRequesting: false,
  sendReceiptError: null,
  sendReceiptEmail: null,
  receipt: null,
  receiptRequesting: false,
  receiptError: null
}

export default function receipt (state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_RECEIPT_REQUEST:
    return {
      ...state,
      receiptRequesting: true,
      receiptError: null
    }
  case GET_RECEIPT_SUCCESS:
    return {
      ...state,
      receiptRequesting: false,
      receiptError: null,
      receipt: action.receipt
    }
  case GET_RECEIPT_ERROR:
    return {
      ...state,
      receiptRequesting: false,
      receiptError: action.error
    }
  case SEND_RECEIPT_REQUEST:
    return {
      ...state,
      sendReceiptRequesting: true,
      sendReceiptError: null,
      sendReceiptEmail: null
    }
  case SEND_RECEIPT_SUCCESS:
    return {
      ...state,
      sendReceiptRequesting: false,
      sendReceiptError: null,
      sendReceiptEmail: action.email
    }
  case SEND_RECEIPT_ERROR:
    return {
      ...state,
      sendReceiptRequesting: false,
      sendReceiptError: action.error,
      sendReceiptEmail: null
    }
  case RESET_RECEIPT:
    return {
      ...state,
      sendReceiptRequesting: false,
      sendReceiptError: null,
      sendReceiptEmail: null,
      receipt: null
    }
  case RESET_SEND_RECEIPT:
    return {
      ...state,
      sendReceiptRequesting: false,
      sendReceiptError: null,
      sendReceiptEmail: null
    }
  default:
    return state
  }
}
