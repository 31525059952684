import {
  MARKETPLACE_SLUG_REQUEST,
  MARKETPLACE_SLUG_ERROR,
  MARKETPLACE_REQUEST,
  MARKETPLACE_SUCCESS,
  MARKETPLACE_ERROR,
  MARKETPLACE_DETAILS_REQUEST,
  MARKETPLACE_DETAILS_SUCCESS,
  MARKETPLACE_DETAILS_ERROR,
  MARKETPLACE_TREE_REQUEST,
  MARKETPLACE_TREE_SUCCESS,
  MARKETPLACE_TREE_ERROR,
  LOGOUT_SUCCESS,
  UPDATE_SELLER_OWNER_DETAILS_REQUEST,
  UPDATE_SELLER_OWNER_DETAILS_SUCCESS,
  UPDATE_SELLER_OWNER_DETAILS_ERROR
} from '../Constants/ActionTypes'

const INITIAL_STATE = {
  id: null,
  name: null,
  slug: null,
  rootDetails: null,
  details: null,
  isRequesting: false,
  requestSuccess: null,
  requestError: false,
  isDetailsRequesting: false,
  requestDetailsSuccess: null,
  requestDetailsError: false,
  isTreeRequesting: false,
  affiliationTree: [],
  requestTreeError: null,
  lastSelectedMarketplaceId: null,
  customSlugRequesting: false,
  customSlugError: null,
  updateSellerOwnerRequest: false,
  updateSellerOwnerSuccess: false,
  updateSellerOwnerError: null
}

export default function marketplace (state = INITIAL_STATE, action) {
  switch (action.type) {
  case MARKETPLACE_REQUEST:
    return {
      ...state,
      isRequesting: true,
      requestSuccess: false,
      requestError: null
    }
  case MARKETPLACE_SLUG_REQUEST:
    return {
      ...state,
      isRequestingSlug: true,
      requestSlugError: null
    }
  case MARKETPLACE_SLUG_ERROR:
    return {
      ...state,
      isRequestingSlug: false,
      requestSlugError: action.error
    }
  case MARKETPLACE_SUCCESS:
    return {
      ...state,
      isRequesting: false,
      requestSuccess: true,
      requestError: null,
      id: action.id,
      slug: action.slug,
      name: action.name
    }
  case MARKETPLACE_ERROR:
    return {
      ...INITIAL_STATE,
      isRequesting: false,
      requestSuccess: false,
      requestError: action.error
    }
  case MARKETPLACE_DETAILS_REQUEST:
    return {
      ...state,
      isDetailsRequesting: true,
      requestDetailsSuccess: false,
      requestDetailsError: null
    }
  case MARKETPLACE_DETAILS_SUCCESS:
    return {
      ...state,
      isDetailsRequesting: false,
      requestDetailsSuccess: true,
      requestDetailsError: null,
      details: action.details,
      rootDetails: state.rootDetails ? state.rootDetails : action.details,
      lastSelectedMarketplaceId: action.details.id
    }
  case MARKETPLACE_DETAILS_ERROR:
    return {
      ...INITIAL_STATE,
      isDetailsRequesting: false,
      requestDetailsSuccess: false,
      requestDetailsError: action.error
    }
  case MARKETPLACE_TREE_REQUEST:
    return {
      ...state,
      isTreeRequesting: true,
      affiliationTree: [],
      requestTreeError: null
    }
  case MARKETPLACE_TREE_SUCCESS:
    return {
      ...state,
      isTreeRequesting: false,
      affiliationTree: action.tree,
      requestTreeError: null
    }
  case MARKETPLACE_TREE_ERROR:
    return {
      ...state,
      isTreeRequesting: false,
      affiliationTree: [],
      requestTreeError: action.error
    }
  case UPDATE_SELLER_OWNER_DETAILS_REQUEST:
    return {
      ...state,
      updateSellerOwnerRequest: true,
      updateSellerOwnerError: null,
      updateSellerOwnerSuccess: false
    }
  case UPDATE_SELLER_OWNER_DETAILS_SUCCESS:
    return {
      ...state,
      updateSellerOwnerRequest: false,
      updateSellerOwnerError: null,
      updateSellerOwnerSuccess: true,
      details: {
        ...state.details,
        customer: action.seller
      }
    }
  case UPDATE_SELLER_OWNER_DETAILS_ERROR:
    return {
      ...state,
      updateSellerOwnerRequest: true,
      updateSellerOwnerError: action.error,
      updateSellerOwnerSuccess: false
    }
  case LOGOUT_SUCCESS:
    return {
      ...INITIAL_STATE,
      lastSelectedMarketplaceId: state.lastSelectedMarketplaceId
    }
  default:
    return state
  }
}
